@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400italic,600,700%7COpen+Sans:300,400,400italic,600,700);

$ss-pro: 'Source Sans Pro', sans-serif;
$open-sans: 'Open Sans', sans-serif;

.ss-pro {
  font-family: $ss-pro;
}

.open-sans {
  font-family: $open-sans;
  font-weight: 400;
}

@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,300i,400,500,500i,700|Inconsolata);

$libre: 'Libre Franklin', sans-serif;
$mono: 'Inconsolata', sans-serif;

.libre-light {
  font-family: $libre;
  font-weight: 300;
}

.libre {
  font-family: $libre;
  font-weight: 400;
}

.libre-medium {
  font-family: $libre;
  font-weight: 500;
}

.libre-medium-i {
  font-family: $libre;
  font-weight: 500;
  font-style: italic;
}

.libre-bold {
  font-family: $libre;
  font-weight: 700;
}

.mono {
  font-family: $mono;
}

@import url(https://fonts.googleapis.com/css?family=Roboto|Lobster);

$roboto: 'Roboto', sans-serif;
$lobster: 'lobster', serif;

@import url(https://fonts.googleapis.com/css?family=Kanit);

$chango: 'Kanit', sans-serif;
