.notification-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  z-index: 1000;

  @include box-shadow(0, 0, 4px, rgba($black, 0.5));

  &__body {
    width: 480px;
    height: 280px;
  }

  &__content {
    width: 90%;
    margin: 40px auto 0 auto;
    text-align: center;
  }
}

#notification {
  z-index: 1000;
}
