.register-transactions {
  &__row {
    margin-bottom: 0 !important;
  }

  &__block {
    // height: auto;
    overflow: hidden;

    @include transition(height, 0.5s);
  }

  &__price {
    width: 100%;
    font-size: 24px;
    text-align: left;
    margin-bottom: 5px;
  }
}
