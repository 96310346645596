.app {
  position: relative;
  padding: #{$header-height} 0 0 0;
  height: 100%;
  // padding: 0 0 0 #{$sidebar-width};
  padding: #{$header-height} 0 0 #{$sidebar-width};
  // overflow-y: scroll;

  @include transition(all, 0.2s);

  background-color: #2b6e80 20%;
  // background-image: radial-gradient(farthest-side ellipse at 10% 0, #2b6e80 20%, #0c3252);
  // background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  // background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  background-attachment: fixed, fixed;

  &__tablet {
    padding: #{$header-height} 0 0 0;
  }

  &__yellow {
    background-color: #414e60 20%;
    background-image: radial-gradient(farthest-side ellipse at 10% 0, #414e60 20%, #94a3b1);
    background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);
    background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);
  }

  // &__yellow {
  //     background-color: #819954 20%;
  //     background-image: radial-gradient(farthest-side ellipse at 10% 0, #819954 20%, #899670);
  //     background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #819954 20%, #899670);
  //     background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #819954 20%, #899670);
  // }

  // background-color: #414e60 20%;
  // background-image: radial-gradient(farthest-side ellipse at 10% 0, #414e60 20%, #94a3b1);
  // background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);899670
  // background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);
  // background-attachment: fixed, fixed;
  // &__container {
  //     position: fixed;
  //     top: 40px;
  //     left: 0;
  //     width: 100%;
  //     min-width: 1024px;
  //     height: calc(100% - 40px);
  //     min-height: 728px;
  //     background-color: $white;
  // }

  // &__resolution {
  //     position: fixed;
  //     top: 40px;
  //     left: 0;
  //     width: 100%;
  //     height: calc(100% - 40px);
  //     z-index: 999;
  //     background-color: rgba($grey-1, .9);
  //     display: none;

  //     &--title {
  //         width: 92%;
  //         margin: 400px auto;
  //         font-size: 36px;
  //         text-align: center;
  //     }
  // }
}
