.widget {
  border-radius: 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 12px 17px;
  color: #606060;
  background: #fff;
  margin-bottom: 30px;
  position: relative;

  @include transition(opacity, 0.2s);

  &__block {
    width: calc(100% + 34px);
    height: calc(100% - 45px);
    overflow-y: auto;
    margin: 0 -17px;
  }

  &__overflow {
    margin: 10px -17px 0 -17px;
  }

  &__float-input {
    display: inline-block;
    margin: 0 20px 10px 0;

    &--label {
      font-size: 13px;
      font-weight: 600;
      line-height: 18.6px;
      font-color: $font-color;
      margin-bottom: 5px;

      @extend .open-sans;
    }
  }

  &__buttons {
    width: 100%;
    margin-top: 20px;

    @include clearAfter();

    &--row {
      margin-bottom: 7px;

      @include clearAfter();
    }

    &--right {
      float: right;
      min-width: 80px;

      @extend .button;
      @extend .button__success;
    }

    &--left {
      float: left;
      min-width: 80px;

      @extend .button;
      @extend .button__success;
    }

    &--std {
      min-width: 80px;

      @extend .button;
      @extend .button__success;
    }

    &--left-danger {
      float: left;
      min-width: 80px;

      @extend .button;
      @extend .button__danger;
    }

    &--right-danger {
      float: right;
      min-width: 80px;

      @extend .button;
      @extend .button__danger;
    }

    &--left-warning {
      float: left;
      min-width: 80px;

      @extend .button;
      @extend .button__warning;
    }

    &--right-warning {
      float: right;
      min-width: 80px;

      @extend .button;
      @extend .button__warning;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgb(96, 96, 96);
    min-height: 30px;
  }

  .upload-btn {
    margin-right: 20px;
    margin-top: 20px;
  }

  .increase-btn {
    margin-left: 10px;
    width: 30px;
    height: 35px;
    color: $black;

    i {
      margin-top: 5px;
    }

    @extend .btn-green;
  }
}
