.group-select {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba($white, .1);
  z-index: 9999;
  cursor: crosshair;

  &__box {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: rgba($green, 0.2);
    border: 1px solid $green;
    display: none;
  }

  &__count {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    height: 120px;
    background-color: $white;
    border-top-left-radius: 10px;
    text-align: center;

    @include box-shadow(0, 0, 20px, $black);

    &--title {
      font-size: 24px;
      width: 100%;
      margin-top: 15px;
    }

    &--count {
      font-size: 42px;
      font-weight: 600;
    }
  }
}
