.checkbox {
  position: relative;
  width: 20px;
  height: 20px;

  &__label {
    position: absolute;
    margin-left: 7px;
    cursor: pointer;
    white-space: nowrap;
  }

  &__click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 2;
  }
}
