.btn-blue {
  height: 40px;
  font-family: Verdana, Lato, Helvetica, sans-serif;
  color: rgba($black, 0.55);
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: rgba($blue, 0.25) rgba($blue, 0.25) rgba($blue, 0.25);
  padding: 0 12px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  @include transition(all, 0.15s, ease);
  @include box-shadow(0, 1px, 14px, rgba(0, 0, 0, 0.1));

  // v2
  @include linearGradient(rgba($blue-2, 0.6), rgba($blue-2, 0.9));

  &:hover {
    color: rgba($black, 0.9);

    // v2
    @include linearGradient(rgba($blue-2, 0.9), rgba($blue-2, 1));
  }

  &__disabled {
    color: rgba($grey-4, 0.6) !important;
    border-color: $grey-1 $grey-2 $grey-1 !important;

    @include linearGradient(rgba($grey-1, 0.9), rgba($grey-2, 0.9));

    &:hover {
      color: rgba($black, 0.7) !important;

      @include linearGradient($grey-1, $grey-2);
    }
  }
}
