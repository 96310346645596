.popup {
  position: absolute;
  top: 0;
  left: 50%;
  min-height: 120px;
  min-width: 320px;
  max-height: calc(100vh - 100px);
  max-width: 90%;
  margin: 50px 0;
  background-color: $white;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 3px;
  transform: translate(-50%, 0);

  @include box-shadow(0, 0, 14px, rgba($black, 0.5));
  @include transition(opacity, 0.2s);

  &__header {
    position: relative;
    width: 100%;
    height: 32px;
    min-width: 320px;
    background-color: $grey-1;
    display: flex;
    flex-shrink: 0;
  }

  &__content {
    position: relative;
    width: 100%;
    height: calc(100% - 32px);
    overflow-y: auto;
    display: block;

    &--padding {
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }

  &__children {
    margin-left: 20px;
  }

  &__footer {
  }
}
