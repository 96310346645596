.test {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: $white;
  // height: 100%;
  max-height: calc(100vh - 100px);
  // max-height: 600px;
  margin: 50px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 90%;

  &__header {
    position: relative;
    width: 100%;
    height: 32px;
    min-width: 320px;
    background-color: $grey-1;
    display: flex;
    flex-shrink: 0;
  }

  &__content {
    position: relative;
    width: 100%;
    height: calc(100% - 32px);
    overflow-y: auto;
    display: block;
  }

  &__footer {
  }
}

.test-smokescreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.8);
  z-index: $popup;
  // padding-top: 50px;
  // padding-bottom: 50px;

  @include transition(opacity, 0.1s);

  &__white {
    background-color: rgba($black, 0.25);
  }

  &__transparent {
    background-color: rgba($black, 0);
  }
}
