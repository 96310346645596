.srtxt {
  margin: 0;
  min-height: 24px;

  &__label {
    position: relative;
    padding: 0;

    &--title {
      position: relative;
      top: 50%;
      width: 96%;
      transform: translateY(-50%);
      padding: 0;
      box-sizing: border-box;

      span {
        color: $red;
      }
    }
  }

  &__content {
    position: relative;
    padding: 0;
    box-sizing: border-box;
    line-height: 20px;

    &--txt {
      @include vertical-center();
    }
  }
}
