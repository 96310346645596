.calendar {
  position: relative;

  &__content {
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 350px;
    background-color: $white;
    opacity: 0;
    border-radius: 4px;
    border: 1px solid rgba($black, 0.25);
    z-index: 999999;

    @include box-shadow(0, 0, 20px, $grey);
  }

  &__prev,
  &__next {
    position: absolute;
    top: 2px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    line-height: 25px;
    cursor: pointer;
  }

  &__prev {
    left: 10px;
  }

  &__next {
    right: 10px;
  }

  &__close {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  &__date {
    outline: none;
    text-align: right;

    &--active {
      @include box-shadow(0, 0, 10px, $orange-light);
    }
  }

  &__date_left {
    outline: none;
    text-align: left;

    &--active {
      @include box-shadow(0, 0, 10px, $orange-light);
    }
  }

  &__clear {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 2;

    &--close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      font-size: 18px;
      cursor: pointer;
    }

    &--year {
      position: relative;
      margin: 80px 20px 20px 20px;

      select {
        // width: calc(100% - 27px)!important;
        width: 100% !important;
        @extend .input-2;
      }
    }

    &--month {
      display: inline-block;
      width: 50%;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
