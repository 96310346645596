.content-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
  max-width: 100%;
  max-height: 90%;
  background-color: $grey-1;
  opacity: 0;

  margin: 0 auto;
  padding: 30px 30px 1px;
  // overflow-x: visible;

  @include transition(opacity, 0.1s);
}
