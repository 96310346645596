.auth {
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $auth;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('/assets/auth.jpg');

    @include blur(2px);
  }

  &__content {
    position: relative;
    width: 500px;
    height: 460px;
    background-color: $white;
    padding: 20px;

    @include center-center();
    @include box-shadow(0, 0, 18px, $black);

    &--logo {
      padding: 20px 0 40px 0;
      text-align: center;

      img {
        width: 320px;
      }
    }
    &--right {
      float: right;
      padding-top: 20px;
      padding-right: 10px;
      background-color: $white;
    }
  }

  p {
    padding: 10px 0 20px 0;
  }

  &__go {
    margin-top: 10px;
    width: 60px;
  }

  &__reset {
    margin-top: 10px;
    width: 90px;
  }

  &__message {
    position: absolute;
    bottom: 30px;
    right: 80px;
    font-size: 16px;
    color: $red;
  }

  &__version {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 11px;
    color: rgba($white, 0.5);
    z-index: 22;
  }
}

.auth {
  input {
    width: 100%;
    padding: 12px 15px;
    background: #f8f8f8;
    border: 1px solid rgba($green, 0.8);
    border-radius: 5px;
    margin-bottom: 10px;
    color: $grey-4 !important;
    font-size: 16px;

    @include transition(all, 0.2s);

    &:focus {
      border-color: rgba($green, 0.6);
      outline-width: 0;

      // @include box-shadow(0, 0, 20px, $grey-1);
    }
  }

  &__error {
    background-color: $input-error !important;
  }
}

// .auth {
// 	position: fixed;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	background-color: $black;
// 	z-index: 9999;
//     display: inline;

//     @include transition(all, .5s);

//     &__bg {
//     	position: absolute;
//     	top: 0;
//     	left: 0;
//     	width: 100%;
//     	height: 100%;
// 		opacity: 0;
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-position: center;
// 		background-color: $black;
// 	    background-image: url('/assets/auth.jpg');

// 		@include transition(all, 2s);
//     }

//     &__block {
//     	width: 320px;
//     	background-color: $white;
//         border-radius: 4px;

//     	@include center-center();
//     	@include box-shadow(0px, 0px, 28px, rgba($grey-1, .25));

//     	&--header {
//     		position: relative;
//     		widows: 100%;
//     		height: 45px;
//     		background-color: $grey-2;
//     		text-align: center;
//             border-top-right-radius: 4px;
//             border-top-left-radius: 4px;

//     		img {
//     			position: relative;
//     			top: 50%;
//     			transform: translateY(-50%);
//     			height: 30px;
//     		}
//     	}
//     }

//     &__content {
//     	height: 235px;
//     	padding-top: 40px!important;
//     	padding-bottom: 10px!important;

// 	    &--form-control {
// 	    	width: 100%;
// 	    	height: 40px;
// 	    	border: 1px solid rgba($grey-3, .25);
// 	    	border-radius: 3px;
// 	    	padding-left: 15px;
// 	    }

// 	    &--error {
// 	    	position: relative;
//     		top: -3px;
// 	    	font-size: 13px;
// 	    	font-weight: 600;
// 	    	font-style: italic;
// 	    	color: $error;

// 	    	@extend .open-sans;
// 	    }
// 	}
// }
