.timeclock {
  &__header {
    // font-size: 16px;
    border-bottom: 1px solid $grey;

    td:first-child {
      width: 300px;
    }

    td:nth-child(2) {
      width: 250px;
    }

    td:nth-child(3) {
      width: 250px;
    }
  }

  &__border {
    border-bottom: 1px solid $grey;
  }

  &__name {
    padding-top: 20px;
  }

  &__time {
    font-size: 16px;
    margin: 0 4px 0 10px;
  }

  &__table {
    cursor: pointer;

    &:hover {
      background-color: rgba($grey, 0.25) !important;
    }
  }

  &__edit {
    font-style: italic;
    margin-left: 10px;
  }
}
