.cal-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  color: $black;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;

  @extend .open-sans;

  &__first {
    span {
      cursor: pointer;
    }
  }
}
