.cal-todo {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  background-color: #3e454d;

  &__header {
    font-family: $roboto;
    color: $orange;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
