.print-table {
  &__header {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__check {
    display: inline-block;
    width: 40px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  &__title {
    display: inline-block;
    width: 310px;
    margin-bottom: 10px;

    input {
      width: 100%;
    }
  }

  &__align {
    display: inline-block;
    width: 110px;
    margin-left: 10px;
    margin-bottom: 10px;

    input {
      width: 100%;
    }
  }

  &__table {
    display: none;

    position: fixed;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    background-color: $white;
  }
}
