.data-load-card {
  background-color: white;
  @include box-shadow(0, 0, 16px, $grey);
  border: 1px solid black;
  border-radius: 0.5rem;
  margin: 1rem;
  height: 300px;
  width: 250px;
  overflow: scroll;
  display: inline-block;
  cursor: pointer;
}

.data-load-card-header {
  border-bottom: 1px black solid;
  padding: 1rem;
  position: sticky;
  background: white;
  top: 0;
}

.data-load-card-row {
  padding: 0.25rem 0rem 0.25rem 0.3rem;
  border-bottom: 1px lightgrey solid;

  &:last-child {
    border-bottom: none;
  }
}

.data-load-item-title {
  width: 80%;
  display: inline-block;
}

.data-load-item-rows {
  width: 20%;
  display: inline-block;
}

.canceled {
  color: darkred;
  font-weight: 400;
}

.completed {
  color: green;
  font-weight: 400;
}
