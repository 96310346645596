.inv-printer {
  &__desc {
    width: calc(100% - 120px);
  }

  &__qty {
    width: 120px;
  }

  &__desc,
  &__qty {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
