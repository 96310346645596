.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: $sidebar-width;
  height: 100%;
  background-color: $sidebar-color;
  color: $white;
  z-index: $sidebar;

  @include transition(left, 0.2s);

  &__content {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
  }

  // &__content::-webkit-scrollbar {
  // 	display: none;
  // }

  &__logo {
    position: relative;
    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }
}
