.bulk-register-transactions-header {
  left: 0;
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;

  ul {
    top: 30px;
    height: 40px;
    z-index: 1;

    li {
      height: 40px;
      padding: 0 20px;
      background-color: darkgray;
      color: white;
      margin-left: 6px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      cursor: pointer;
      font-size: 20px;
      line-height: 18px;

      @include transition(background-color, 0.2s);
    }
  }

  &__active {
    background-color: white !important;
    color: black !important;
  }
}
