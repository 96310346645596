.select {
  @extend .input-text;

  &__single-row {
    .col-6 {
      padding: 0;
    }

    .form-control__label {
      line-height: 38px;
    }
  }
}
