.switch {
  position: relative;
  display: inline-block;
  width: calc(4.5rem);
  height: calc(2.25rem + 2px);
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: calc(2.25rem - 8px);
  width: calc(2.25rem - 8px);
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  left: 10px;
}
input:checked + .slider {
  background-color: #2c343f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2c343f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
