.table-2 {
  // width: calc(100% - 20px)!important;
  width: 100%;
  // margin: 0 -17px 0 -17px;

  &__overflow {
    position: relative;
    overflow-x: auto;
  }

  &__stretch {
    margin: 0 -17px -27px -17px;
  }

  &__actions {
    width: 1%;
    white-space: nowrap;

    i {
      cursor: pointer;
    }

    div {
      width: 20px;
      height: 100%;
      text-align: center;
      display: inline-block;
    }
  }

  &__selected {
    color: $white !important;
    background-color: #80a8b3 !important;
  }

  &__hover {
    cursor: pointer;

    &--dark {
      background-color: rgba(#80a8b3, 0.8) !important;
    }

    &--light {
      background-color: rgba($grey, 0.25) !important;
    }
  }

  &__filter {
    position: relative;

    &--search {
      position: relative;
      width: 200px;
      height: 40px;
      // float: right;
      // margin-left: 20px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 30px;
        // padding: 4px 10px;
        font-size: 12px;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        color: #808080;
        display: inline-block;
        background-color: #eeeeee;
        border: 1px solid #ccc;
        line-height: 30px !important;
        text-align: center;
      }

      input {
        position: absolute;
        top: 0;
        left: 32px;
        width: 144px;
        height: 30px;
        padding: 0 12px;
        font-size: 13px;
        line-height: 1.428571429;
        color: #555;
        background-color: #fff;
        background-image: none;

        border: 1px solid #ccc;
        border-radius: 3px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      }
    }
  }

  &__hover {
    cursor: pointer;

    // &:hover {
    // 	background-color: rgba($grey, .25)!important;
    // 	color: #606060!important;
    // }
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;

    th {
      position: relative;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      color: rgb(96, 96, 96);
      text-transform: uppercase;
      border-bottom-width: 1px;
      padding: 8px;
      white-space: nowrap;

      img {
        position: absolute;
        top: 14px;
        left: -3px;
        width: 10px;
      }
    }

    th:first-child {
      padding-left: 16px;
    }

    td:first-child {
      padding-left: 16px;
      //border-left: 0!important;
    }

    th:last-child {
      padding-right: 16px;
    }

    td:last-child {
      padding-right: 16px;
    }

    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }

    .table-row-action i {
      margin-right: 3px;
    }

    tr {
      // cursor: pointer;

      td {
        // padding-top: 3px;
        // padding-bottom: 3px;
        // white-space: nowrap;
        // border-top: 1px solid rgba($grey-2, 1);
        // border-left: 1px solid rgba($grey-2, 1);
        // font-size: 14px;

        line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid #ddd;
        padding: 10px;
        white-space: nowrap;

        input[type='checkbox'] {
          cursor: pointer;
        }
      }
    }

    &__search {
      text-align: right;

      label {
        font-size: 13px;
        font-weight: 400;
      }

      input {
        margin-left: 10px;
      }
    }
  }

  &__pages {
    position: relative;

    &--title {
      font-size: 12px;
      cursor: pointer;
    }

    // &--page {
    // 	margin: 0 3px;
    // 	cursor: pointer;
    // 	color: $grey-2;

    // 	&:hover {
    // 		color: $black;
    // 	}
    // }

    // &--active {
    // 	color: $black;
    // }

    &--overflow {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    &--dropdown {
      position: absolute;
      top: 18px;
      left: -2px;
      width: 100px;
      height: 200px;
      background-color: $white;
      z-index: 2;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 6px;
      overflow: hidden;

      @include transition(opacity, 0.2s);

      div {
        cursor: pointer;
      }
    }
  }

  &__page-bar {
    height: 20px;
    padding-top: 10px;

    &--btn {
      width: 23px;
      display: inline-block;
      color: #606060;
      padding: 4px 4px;
      font-size: 12px;
      text-align: center;
      line-height: 1.428571429;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #eeeeee;
      }
    }

    &--active {
      background-color: #eeeeee;
    }
  }

  .col-10 {
    width: 10%;
  }
  .col-20 {
    width: 20%;
  }
  .col-30 {
    width: 30%;
  }
  .col-40 {
    width: 40%;
  }
  .col-50 {
    width: 50%;
  }
  .col-60 {
    width: 60%;
  }
  .col-70 {
    width: 70%;
  }
  .col-80 {
    width: 80%;
  }
  .col-90 {
    width: 90%;
  }

  &__no-select {
    @include disableSelect();
  }

  &__sort-icon {
    line-height: 18px;
    margin-left: 10px;
  }
}
