.block {
  position: relative;
  background-color: $white;
  margin-bottom: 30px;
  // overflow: hidden;

  @include box-shadow(0, 2px, 10px, rgba($grey-4, 0.05));

  &__content {
    margin: 0 auto;
    padding: 20px 20px 1px;
    max-width: 100%;
    overflow: hidden;
  }
}

// a.block {
// display: block;
// color: #646464;
// font-weight: normal;
// -webkit-transition: all 0.15s ease-out;
// transition: all 0.15s ease-out;
// }
// a.block:hover {
// color: #646464;
// opacity: .9;
// }
// a.block.block-link-hover1:hover {
// -webkit-box-shadow: 0 2px rgba(0, 0, 0, 0.1);
// box-shadow: 0 2px rgba(0, 0, 0, 0.1);
// opacity: 1;
// }
// a.block.block-link-hover1:active {
// -webkit-box-shadow: 0 2px rgba(0, 0, 0, 0.01);
// box-shadow: 0 2px rgba(0, 0, 0, 0.01);
// }
// a.block.block-link-hover2:hover {
// -webkit-transform: translateY(-2px);
// -ms-transform: translateY(-2px);
// transform: translateY(-2px);
// -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
// box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
// opacity: 1;
// }
// a.block.block-link-hover2:active {
// -webkit-transform: translateY(-1px);
// -ms-transform: translateY(-1px);
// transform: translateY(-1px);
// -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
// box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
// }
// a.block.block-link-hover3:hover {
// -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
// box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
// opacity: 1;
// }
// a.block.block-link-hover3:active {
// -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
// box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
// }
