.inv-add {
  &__header {
    padding: 10px;
  }

  &__desc {
    width: calc(100% - 160px);
  }

  &__qty {
    width: 160px;
  }

  &__desc,
  &__qty,
  &__unit {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__row {
    &--details {
      padding: 0 10px;
      border-bottom: 1px solid $grey-2;
    }
  }
}
