.widget-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  // height: auto;
  transform: translate(-50%, -50%);
  background-color: $white;
  max-width: 96%;
  max-height: 80%;
  // max-height: calc(100% - 200px);
  padding: 12px 17px;
  border-radius: 3px;
  color: #606060;
  // overflow: hidden;
  // -webkit-box-sizing: content-box;
  // -moz-box-sizing: content-box;
  // box-sizing: content-box;

  @include box-shadow(0, 0, 14px, rgba($black, 0.5));
  @include transition(opacity, 0.2s);

  &__header {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgb(96, 96, 96);
    min-height: 30px;
    max-width: 96%;
    // -webkit-box-sizing: content-box;
    // -moz-box-sizing: content-box;
    // box-sizing: content-box;
    span {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      color: rgb(153, 153, 153);
    }

    &--close {
      position: fixed;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      color: #606060;

      // font-size: 24px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;

      @include transition(all, 0.2s);

      &:hover {
        color: $black;
      }
    }

    &--add {
      position: fixed;
      top: 0;
      right: 32px;
      width: 32px;
      height: 32px;
      color: #606060;

      // font-size: 24px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;

      @include transition(all, 0.2s);

      &:hover {
        color: $black;
      }
    }
  }

  &__block {
    position: relative;
    // width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -12px;
  }

  &__content {
    position: relative;
    width: 100%;
    background-color: $white;
    padding: 10px;

    @include clearAfter();

    &--alert {
      font-size: 32px;
      padding: 20px;
    }
  }

  &__disabled {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.75);
    z-index: 10;
  }
}
