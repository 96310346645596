.discounts {
  width: 100%;

  tr:nth-child(odd) {
    background-color: $grey-2;
  }

  tr:nth-child(even) {
    background-color: $grey-1;
  }

  tr {
    td:first-child {
      width: 120px;
      font-size: 16px;
      font-weight: 600;
      padding-left: 10px;
    }

    td:nth-child(2) {
      width: 100px;
    }

    td:nth-child(3) {
      width: 100px;
    }

    td:nth-child(4) {
      width: 120px;
      padding: 0 5px;
    }

    td:nth-child(5) {
      width: 120px;
      padding: 5px;
    }
  }
}
