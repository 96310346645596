.drf {
  display: inline-block;
  // margin: 0 20px 10px 0;

  &__label {
    font-size: 13px;
    // font-weight: 600;
    line-height: 18.6px;
    font-color: $font-color;
    margin-bottom: 5px;

    @extend .open-sans;

    span {
      color: $red;
    }
  }

  &__clear {
    display: block;
  }
}
