.inv-comb {
  &__desc {
    width: calc(100% - 320px);
  }

  &__qty {
    width: 160px;
  }

  &__unit {
    width: 160px;
  }

  &__desc,
  &__qty,
  &__unit {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
