.export-table {
  &__block {
    position: relative;
    // width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    // margin: 0 -12px;
  }

  &__left {
    display: inline-block;
    width: calc(10% - 10px);
    margin-bottom: 10px;
    margin-left: 10px;
  }

  &__right {
    display: inline-block;
    width: 80%;
    margin-bottom: 10px;

    input {
      width: 100%;
    }
  }
}
