.header {
  position: fixed;
  top: 0;
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
  height: $header-height;

  z-index: $header;

  @include transition(all, 0.2s);

  background-color: $white;
  border-bottom: 1px solid;
  border-color: rgba($grey-1, 0.9);

  @include box-shadow(0, 2px, 10px, rgba($black, 0.5));

  &__test {
    background-color: pink;
  }

  &__options {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba($white, 0.7);
    font-size: 26px;
    line-height: 60px;

    i {
      cursor: pointer;
      padding-right: 20px;

      @include transition(all, 0.1s);

      &:hover {
        color: $white;
      }
    }
  }

  &__button {
    width: 40px;
    height: 40px;
    background-color: rgba($grey, 0.5);
    border-radius: 3px;
    border: 1px solid rgba($grey, 0.8);
    margin-left: 20px;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    color: rgba($black, 0.75);
    display: inline-block;

    @include transition(all, 0.1s);

    &:hover {
      color: $black;

      @include box-shadow(0, 0, 8px, $grey);
    }
  }

  &__loc {
    position: absolute;
    top: 5px;
    left: 80px;

    select {
      border: 0;
      outline: 0;
    }

    &--location {
      position: absolute;
      top: 25px;
      left: 0;
    }
  }

  &__logo {
    position: absolute;
    top: 5px;
    right: 10px;

    img {
      height: 60px;
      opacity: 0.75;
    }
  }
}
