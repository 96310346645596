.notification {
  position: fixed;
  top: 0;
  right: 0;
  width: 440px;
  height: auto;
  // background-color: rgba($lavender, 1);

  z-index: $notification;
}
