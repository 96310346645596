ul.autocomplete {
  display: block;
  list-style: none;
  width: 100%;
  transition: width 0.3s;
  margin: auto;
  position: absolute;
  z-index: 100;
  background: white;
  max-height: 500px;
  overflow-y: scroll;
  border: 1px solid black;
}

ul.autocomplete li {
  display: block;
  padding: 0.25rem;
  font-size: 1rem;
  width: 100%;
  z-index: 100;
  cursor: pointer;
}

ul.autocomplete li.autocomplete-active-option {
  background: #80808024;
}

ul.autocomplete li:hover {
  background: #80808024;
}

input.autocomplete {
  position: relative;
}
