.cal-month {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 280px;
  height: calc(100% - 50px);
  transform: translateX(-50%);

  &__ddd,
  &__day {
    float: left;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-family: $roboto;
    cursor: pointer;
  }

  &__ddd {
    font-size: 12px;
    text-transform: uppercase;
  }

  &__day {
    font-size: 15px;
  }

  &__today {
    background-color: rgba($grey, 0.3);
    border-radius: 50%;
  }

  &__before-today {
    color: $grey-dark;
    cursor: default;
  }

  &__selected {
    box-shadow: 0 0 0 3px $orange-light;
    // border: 2px solid $orange-light;
    border-radius: 50%;
    padding: -2px;
  }
}
