.input-text {
  position: relative;

  &__action {
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    background-color: $grey-2;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-align: center;
    color: $grey-3;

    @include transition(all, 0.1s);

    &:hover {
      color: $black;
    }

    i {
      line-height: 38px;
      font-size: 20px;
    }

    &--left {
      left: 0;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &--right {
      right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  &__label {
    color: $black;
    line-height: 32px;
    font-size: 16px;
  }
}
