.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;

  @include transition(all, 0.2s);

  &__success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c;

    &:hover {
      color: #fff;
      background-color: #449d44;
      border-color: #419641;
    }
  }

  &__danger {
    color: #fff;
    background-color: $red;
    border-color: $red;

    &:hover {
      color: #fff;
      background-color: $red-3;
      border-color: $red-3;
    }
  }

  &__warning {
    color: #fff;
    background-color: $yellow;
    border-color: $yellow;

    &:hover {
      color: #fff;
      background-color: $yellow-1;
      border-color: $yellow-1;
    }
  }
}

.daniel {
  color: $white;
}

.pull-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
