.table-pagination2 {
  height: 20px;
  padding-top: 10px;

  &__btn {
    width: 23px;
    display: inline-block;
    color: #606060;
    padding: 4px 4px;
    font-size: 12px;
    text-align: center;
    line-height: 1.428571429;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #eeeeee;
    }
  }

  &__active {
    background-color: #eeeeee;
  }
}

.table-pagination {
  position: relative;
  width: 305px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid $grey-2;
  box-sizing: border-box;
  // margin-top: 20px;

  div {
    // display: inline-block;
    float: left;
    // width: 40px;
    height: 30px;
    // padding: 0 10px;
    text-align: center;
    font-size: 14px;
    color: $grey-4;
    line-height: 30px;
    cursor: pointer;

    span {
      font-size: 14px;
    }

    i {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__first {
    width: 40px;
    border-right: 1px solid $grey-2;
  }

  &__last {
    width: 40px;
    border-left: 1px solid $grey-2;
  }

  &__label {
    width: 40px;
  }

  &__total {
    font-size: 14px !important;
    width: 140px;
    text-align: center;
    // padding: 0 15px;

    border-left: 1px solid $grey-2;
    border-right: 1px solid $grey-2;
  }
}
