.form-control {
  resize: none;
  box-sizing: border-box;

  @include placeholder {
    font-style: italic;
    color: rgba($black, 0.45);
    font-weight: 100;
  }

  &__label {
    font-size: 13px;
    // font-weight: 600;
    line-height: 18.6px;
    font-color: $font-color;
    margin-bottom: 5px;

    @extend .open-sans;

    span {
      color: $red;
    }
  }
}

.form-control[readonly] {
  background-color: #ffffff;
}

.invalid {
  border: 1px solid red;
  border-radius: 3px;
  &:focus {
    border: 1px solid rgb(255, 66, 66);
    box-shadow: 0 0 3px red;
  }
}
