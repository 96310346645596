.table {
  width: calc(100% - 20px) !important;

  &__overflow {
    position: relative;
    overflow-x: auto;
  }

  &__actions {
    width: 1%;
    white-space: nowrap;

    i {
      cursor: pointer;
    }

    div {
      width: 20px;
      height: 100%;
      text-align: center;
      display: inline-block;
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;

    th {
      position: relative;
      padding: 8px 10px 8px 10px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #646464;
      white-space: nowrap;

      @extend .ss-pro;

      img {
        position: absolute;
        top: 14px;
        left: -3px;
        width: 10px;
      }
    }

    td:first-child {
      border-left: 0 !important;
    }
    .table-row-action i {
      margin-right: 3px;
    }
    td {
      padding-top: 3px;
      padding-bottom: 3px;
      white-space: nowrap;
      border-top: 1px solid rgba($grey-2, 1);
      border-left: 1px solid rgba($grey-2, 1);
      font-size: 14px;

      input[type='checkbox'] {
        cursor: pointer;
      }
    }

    &__search {
      text-align: right;

      label {
        font-size: 13px;
        font-weight: 400;
      }

      input {
        margin-left: 10px;
      }
    }
  }

  &__pages {
    &--title {
      font-size: 12px;
    }

    &--page {
      margin: 0 3px;
      cursor: pointer;
      color: $grey-2;

      &:hover {
        color: $black;
      }
    }

    &--active {
      color: $black;
    }
  }

  .col-10 {
    width: 10%;
  }
  .col-20 {
    width: 20%;
  }
  .col-30 {
    width: 30%;
  }
  .col-40 {
    width: 40%;
  }
  .col-50 {
    width: 50%;
  }
  .col-60 {
    width: 60%;
  }
  .col-70 {
    width: 70%;
  }
  .col-80 {
    width: 80%;
  }
  .col-90 {
    width: 90%;
  }

  &__no-select {
    @include disableSelect();
  }
}
