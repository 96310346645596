$select-input-bg-disabled: inherit;
$select-input-bg-focus: inherit;
$select-input-bg: $white;
$select-input-box-shadow-focus: inherit;

$select-arrow-color: $black;
$select-arrow-width: 4px;
$select-clear-width: 4;
$select-text-color: inherit;
$select-link-hover-color: inherit;
$select-arrow-color-hover: inherit;
$select-input-border-focus: #5cb3fd;
$select-input-border-color: rgba(0, 0, 0, 0.15);
$select-input-border-radius: 0.25rem;
$select-input-border-width: 1px;
$select-input-height: 24px;
$select-input-hover-box-shadow: inherit;
$select-input-placeholder: inherit;
$select-input-internal-height: 20px;
$select-padding-horizontal: inherit;
$select-loading-size: 30;
$select-loading-color-bg: inherit;
$select-loading-color: inherit;
$select-clear-color: inherit;
$select-clear-hover-color: inherit;
$select-clear-size: inherit;

$select-menu-box-shadow: inherit;
$select-menu-max-height: 200;
$select-menu-zindex: 2;
$select-option-bg: inherit;
$select-option-color: inherit;
$select-padding-vertical: inherit;
$select-option-selected-bg: inherit;
$select-option-selected-color: inherit;
$select-option-focused-bg: inherit;
$select-option-focused-color: inherit;
$select-option-disabled-color: inherit;
$select-noresults-color: inherit;
$select-item-gutter: 2px;
$select-item-bg: rgba(0, 0, 0, 0.15);
$select-item-color: #464a4c;
$select-item-font-size: 0.8rem;
$select-item-padding-vertical: 1px;
$select-item-padding-horizontal: 5px;
$select-item-hover-bg: inherit;
$select-item-hover-color: inherit;
$select-item-disabled-bg: inherit;
$select-item-disabled-color: inherit;
$select-item-disabled-border-color: inherit;
$select-item-border-radius: 0.25rem;
$select-item-border-color: rgba(0, 0, 0, 0.15);
$select-item-font-family: sans-serif;
$select-item-line-height: 20px;

@import 'control';
@import 'menu';
@import 'mixins';
@import 'multi';
@import 'spinner';
