.faq {
  &__item {
    margin: 10px 0;
  }

  &__block {
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }

  &__title {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
  }

  &__body {
    height: 0;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;

    @include transition(all, 0.2s);

    h1 {
      font-size: 16px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}
