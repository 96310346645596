.details-popup2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: $white;
  // height: 100%;
  max-height: calc(100vh - 100px);
  // max-height: 600px;
  margin: 50px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 90%;

  @include box-shadow(0, 0, 14px, rgba($black, 0.5));
  @include transition(opacity, 0.2s);

  &__center {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    position: relative;
    // width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    color: rgb(96, 96, 96);
    min-height: 32px;
    // max-width: 96%;
    padding: 0 0 0 10px;

    span {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      color: rgb(153, 153, 153);
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;

    i {
      width: 32px;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: rgb(153, 153, 153);
      cursor: pointer;
      text-align: center;
      // margin-left: 7px;

      @include transition(color, 0.1s);

      &:hover {
        color: rgb(109, 109, 109);
      }
    }
  }

  &__content {
    position: relative;
    height: calc(100% - 32px);
    overflow-y: scroll;
  }

  &__block {
    position: relative;
    width: calc(100% - 20px);
    height: calc(100% - 32px);
    // overflow-y: auto;
    margin-left: 10px;
    // padding-right: 17px;
    padding-bottom: 17px;
  }
  // &--close {
  //     position: fixed;
  //     top: 0;
  //     right: 0;
  //     width: 40px;
  //     height: 40px;
  //     color: #606060;

  //     // font-size: 24px;
  //     text-align: center;
  //     line-height: 40px;
  //     cursor: pointer;

  //     @include transition(all, .2s);

  //     &:hover {
  //         color: $black;
  //     }
  // }

  &__disabled {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgba($white, 0.95);
    z-index: 10;

    span {
      width: 80%;
      text-align: center;
      font-weight: 600;

      @include center-center();
    }
  }
}

/*
 *  STYLE 7
 */

.details-popup__block::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  // background-color: rgba(#F5F5F5, 0);
  // border-radius: 10px;
}

.details-popup__block::-webkit-scrollbar {
  width: 10px;
  background-color: rgba($black, 0);
}

.details-popup__block::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $black;
  // background-image: -webkit-gradient(linear,
  //                              left bottom,
  //                              left top,
  //                              color-stop(0.44, rgb(122,153,217)),
  //                              color-stop(0.72, rgb(73,125,189)),
  //                              color-stop(0.86, rgb(28,58,148)));
}
