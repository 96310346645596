.block-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.9);
  // opacity: 0;
  opacity: 1;
  z-index: 999;
  text-align: center;

  @include transition(opacity, 0.3s);

  img {
    position: relative;
    top: -60px;
    width: 200px;
  }

  i {
    text-align: center;
    font-size: 24px;
    color: rgba($black, 0.75);
  }

  &__narrow {
    margin-left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 30px);
  }
}
