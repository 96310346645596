.inventory {
  &__input {
    width: 160px;
    display: inline-block;
    margin: 0 20px 10px 0;
  }
}

.inventory-bulk-action .package-list {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

#upload-button-container > .upload-button {
  display: inline-block;
  margin-bottom: 0;
}

#upload-button-container > .upload-button > label {
  margin-right: 20px;
  margin-bottom: 0;
}
