.widget-controls {
  position: absolute;
  top: 0;
  right: 0;
  i {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: rgb(153, 153, 153);
    cursor: pointer;
    margin-left: 7px;

    @include transition(color, 0.1s);

    &:hover {
      color: rgb(109, 109, 109);
    }
  }
}
