.table-settings {
  &__row {
    position: relative;
    height: 24px;
    padding-left: 20px;

    @include transition(all, 0.1s);

    &:hover {
      background-color: $grey-1;
    }

    &:hover > &--order {
      display: inline-block;
    }

    &--order {
      position: absolute;
      right: 20px;
      top: 0;
      width: auto;
      height: 24px;
      font-size: 32px;
      line-height: 24px;
      display: none;

      i {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
