.pos-layout {
  &__guest-list {
    width: 100%;
    min-height: 60px;
    margin-bottom: 20px;
    background-color: #f2f2f2;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid hsla(0, 0%, 87%, 0.35);

    &--title {
      // width: 550px;
      color: $black;
      padding-left: 10px;
      padding-top: 5px;
      min-height: 30px;
      font-weight: 700;
      // display: inline-block;
    }
  }
}
