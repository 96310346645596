.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1000;
  opacity: 0;
  display: none;
  background-color: $black;

  @include transition(opacity, 0.4s, ease);

  &__body {
    width: calc(100% - 40px);
    height: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 40px;

    @extend .libre-bold;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: $grey-2;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    cursor: pointer;
  }

  &__success {
    background-color: $notification-success;
  }

  &__alert {
    background-color: $notification-alert;
  }

  &__error {
    background-color: $notification-error;
  }
}
