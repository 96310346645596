.input {
  top: 0;
  left: 32px;
  // width: 144px;
  height: 38px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  background-image: none;

  border: 1px solid #ccc;
  border-radius: 3px;

  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
