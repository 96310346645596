.date-time {
  position: relative;

  &__calendar {
    width: calc(100% - 100px) !important;
  }

  &__time {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px !important;
    text-align: right;

    @extend .input-text;
  }
}
