.permission-module {
  position: relative;

  &__title {
    height: 30px;
    font-size: 16px;
    border-bottom: 1px solid $grey-1;
    cursor: pointer;
    line-height: 30px;

    @include transition(all, 0.1s);

    &:hover {
      // background-color: $grey-1;
    }
  }

  &__checkbox {
    padding: 4px 10px 4px 10px;
  }

  &__count {
    position: absolute;
    top: 0;
    right: 0;
  }
}
