@mixin MQ($media) {
  @if $media == XXXS {
    @media only screen and (max-width: $XXXS) {
      @content;
    }
  } @else if $media == XXS {
    @media only screen and (min-width: $XXS + 1) and (max-width: $XS - 1) {
      @content;
    }
  } @else if $media == XS {
    @media only screen and (min-width: $XS + 1) and (max-width: $S - 1) {
      @content;
    }
  } @else if $media == S {
    @media only screen and (min-width: $S + 1) and (max-width: $L - 1) {
      @content;
    }
  } @else if $media == L {
    @media only screen and (min-width: $L + 1) and (max-width: $XL - 1) {
      @content;
    }
  } @else if $media == XL {
    @media only screen and (min-width: $XL + 1) and (max-width: $XXL - 1) {
      @content;
    }
  } @else if $media == XXL {
    @media only screen and (min-width: $XXL) {
      @content;
    }
  }
}

@mixin MQBelow($media) {
  @if $media == XXXS {
    @media only screen and (max-width: $XXXS) {
      @content;
    }
  } @else if $media == XXS {
    @media only screen and (max-width: $XXS) {
      @content;
    }
  } @else if $media == XS {
    @media only screen and (max-width: $XS) {
      @content;
    }
  } @else if $media == S {
    @media only screen and (max-width: $small) {
      @content;
    }
  } @else if $media == L {
    @media only screen and (max-width: $L) {
      @content;
    }
  } @else if $media == XL {
    @media only screen and (max-width: $XL) {
      @content;
    }
  } @else if $media == XXL {
    @media only screen and (max-width: $XXL) {
      @content;
    }
  } @else if $media == Tablet {
    @media only screen and (max-width: $Tablet) {
      @content;
    }
  }
}

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);

  /* also accepts left, right, top, bottom coordinates; not required, but a good idea for styling */
  // -webkit-transform-origin: 50% 50%;
  // -moz-transform-origin: 50% 50%;
  // -ms-transform-origin: 50% 50%;
  // -o-transform-origin: 50% 50%;
  // transform-origin: 50% 50%;

  /* Should be unset in IE9+ I think. */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #ccc) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}

@mixin center-center($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin vertical-center($position: absolute) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin blur($val) {
  -webkit-filter: blur($val);
  -moz-filter: blur($val);
  -o-filter: blur($val);
  -ms-filter: blur($val);
  filter: blur($val);
}

@mixin linearGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin clearAfter() {
  &:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@mixin disableSelect() {
  user-select: none; /* CSS3 (little to no support) */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none; /* Gecko (Firefox) */
  -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

// @mixin placeholder() {
//     ::-webkit-input-placeholder {@content}
//     :-moz-placeholder {@content}
//     ::-moz-placeholder {@content}
//     :-ms-input-placeholder {@content}
// }

// @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
//     @if $inset {
//         -webkit-box-shadow: inset $top $left $blur $color;
//         -moz-box-shadow: inset $top $left $blur $color;
//         box-shadow: inset $top $left $blur $color;
//     } @else {
//         -webkit-box-shadow: $top $left $blur $color;
//         -moz-box-shadow: $top $left $blur $color;
//         box-shadow: $top $left $blur $color;
//     }
// }

// @mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
//     position: $position;
//     top: $top;
//     right: $right;
//     bottom: $bottom;
//     left: $left;
// }

// @mixin absolute($args) {
//     @include position("absolute", $args);
// }

// @mixin fixed($args) {
//     @include position("fixed", $args);
// }

// @mixin relative($args) {
//     @include position("relative", $args);
// }

// $breakpoints: (
//     'tiny':   ( max-width:  767px ),
//     'small':  ( min-width:  768px ),
//     'medium': ( min-width:  992px ),
//     'large':  ( min-width: 1200px ),
//     'custom': ( min-height:  40em )
// );

// @mixin breakpoint($name) {
//     @if map-has-key($breakpoints, $name) {
//         @media #{inspect(map-get($breakpoints, $name))} {
//             @content;
//         }
//     }
//     @else {
//         @warn "Couldn't find a breakpoint named `#{$name}`.";
//     }
// }

// @mixin MQ($media) {
//     @if $media == S {
//         @media only screen and (max-width: $break-small) { @content; }
//     }
//     @else if $media == M {
//         @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
//     }
//     @else if $media == L {
//         @media only screen and (min-width: $break-large) { @content; }
//     }
// }

// @mixin BP($point) {
//     @if $point == s {
//         @media (max-width: 320px) { @content; }
//     }
//     @else if $point == m {
//         @media (max-width: 640px) { @content; }
//     }
//     @else if $point == l {
//         @media (max-width: 920px)  { @content; }
//     }
//     @else if $point == w {
//         @media (min-width: 1200px) { @content; }
//     }
//     @else if $point == xl {
//         @media (min-width: 1400px) { @content; }
//     }
//     @else if $point == xxl {
//         @media (min-width: 1600px)  { @content; }
//     }
// }
