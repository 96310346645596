.content {
  margin: 0 auto;
  padding: 30px 30px 1px;
  max-width: 100%;
  overflow-x: visible;
}

.main-container {
  min-height: 100%;
}

.max-1600 {
  @include MQ('XXL') {
    max-width: 950px;
  }
}
