.daily-activities {
  max-width: 420px;
  // opacity: 0;

  @include transition(all, 0.3s);

  &__summary-table {
    padding-bottom: 7px;
  }

  &__table {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    font-size: 14px;

    td {
      padding: 6px 4px;
      border-top: 1px solid #f0f0f0;
    }

    td:first-child {
      // border-top: 1px solid #f0f0f0;
      padding-left: 16px;
    }

    td:nth-child(n+1) {
      padding-right: 16px;
    }

    td:nth-child(n+1) {
      text-align: right;
    }

    tr {
      &:hover {
        background-color: rgba($grey, 0.25);
      }
    }
  }

  &__activities-table {
    tr {
      cursor: pointer;

      &:hover {
        background-color: rgba($grey, 0.25);
      }
    }
  }

  &__details {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 350px;
    height: 300px;
  }

  &__submit {
    padding: 0.36rem 1rem !important;
  }
}
