.timepicker {
  position: relative;

  &__content {
    position: absolute;
    top: 45px;
    left: 0;
    width: 286px;
    height: 350px;
    background-color: $white;
    opacity: 0;
    border-radius: 4px;
    border: 1px solid rgba($black, 0.25);
    z-index: 999999;
    overflow: hidden;

    @include box-shadow(0, 0, 20px, $grey);
  }

  &__header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: $blue-dark;
    color: $white;
    text-align: center;
    font-size: 26px;
    line-height: 60px;

    &--am,
    &--pm {
      position: absolute;
      top: 0;
      height: 60px;
      color: $grey-3;
      cursor: pointer;
    }

    &--am {
      left: 20px;
    }

    &--pm {
      right: 20px;
    }

    &--selected {
      color: $white;
    }
  }

  &__table {
    position: relative;
    width: 250px;
    height: 250px;
    background-color: $grey-2;
    color: $black;
    border-radius: 50%;
    margin: 20px auto 0 auto;

    @include box-shadow(0, 0, 20px, $grey);

    &--val {
      position: absolute;
      width: 32px;
      height: 32px;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      font-family: $mono;

      @include transition(all, 0.1s);

      &:hover {
        background-color: $grey-3;
        color: $white;
      }
    }

    &--selected {
      background-color: $grey-3;
      color: $white;
    }
  }
}
