.lsp-loc {
  position: fixed;
  top: 20px;
  left: 20px;
  height: 100%;
  z-index: 0;

  @include transition(all, 0.2s);

  &__tablet {
    top: 10px;
    left: 80px;
  }

  &__lsp {
    font-size: 16px;
    font-weight: 400;
    color: $white;
  }

  &__loc {
    font-size: 14px;
    font-weight: 300;
    color: rgba($white, 0.7);
  }

  &__lsp,
  &__loc {
    span {
      cursor: pointer;
    }
  }

  &__block {
    position: absolute;
    top: 40px;
    left: 0;
    min-width: 200px;
    // min-height: 200px;
    max-height: calc(100% - 80px);
    background-color: $white;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px;
    opacity: 0;
    display: none;
    overflow-y: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid rgba(0, 0, 0, 0.2);

    @include transition(opacity, 0.2s);

    &--item {
      height: 30px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
