.inv-mover {
  &__desc {
    width: calc(100% - 240px);
  }

  &__qty {
    width: 240px;
  }

  &__desc,
  &__qty {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
