.btn {
  cursor: pointer;
  // margin-right: 20px;

  &__wide {
    width: 100% !important;
    margin: 0 !important;
  }

  &__80 {
    width: 80px;
    max-width: 80px !important;
  }

  &__120 {
    width: 120px;
    max-width: 120px !important;
  }
}

.btn-pad {
  margin: 0px 5px 0px 5px;
}

.btn-row {
  height: 50px;
  margin-top: 20px;
}
