// .shift-select {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 200px;
// 	// height: 200px;
// 	z-index: 9999;
// 	display: none;
// 	background-color: $white;
// 	border-radius: 8px;
// 	padding: 10px;
// 	// border: 1px solid $black;

//     @include box-shadow(0, 0, 14px, rgba($black, .5));

//     &__title {
//     	font-size: 16px;
//     	font-weight: 600;
//     	margin-bottom: 20px;
//     }

//     &__action {
//     	font-size: 14px;
//     	cursor: pointer;
//     	margin-bottom: 10px;
//     }
// }

.shift-select {
  &__cog {
    text-align: center;
    font-size: 16px;
  }

  i {
    cursor: pointer;
  }

  &__block {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    // height: 200px;
    z-index: 9999;
    // display: none;
    background-color: $white;
    border-radius: 8px;
    padding: 10px;
    // border: 1px solid $black;

    @include box-shadow(0, 0, 14px, rgba($black, 0.5));
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__action {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}
