.batch-header {
  font-size: 16px;
  font-weight: 400;
  // color: rgba($white, .7);
  color: $black;
  margin-bottom: 20px;

  span {
    // color: $white;
    color: $black;
  }
}
