$break-small: 768px;
$break-large: 1024px;

$sidebar-width: 260px;

$header-height: 75px;

$BP: (
  'xs': 320px,
  's': 640px,
  'm': 920px,
  'l': 1200px,
  'xl': 1400px,
  'xxl': 1600px,
);

$XXXS: 420px;
$XXS: 640px;
$XS: 768px;
$S: 1024px;
$Tablet: 1024px;
$L: 1366px;
$XL: 1400px;
$XXL: 1600px;
