@import 'base/base';
@import 'components/base';
@import 'vendors/base';
@import 'utils/base';

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 1.428571429;

  font-weight: 400;
  // padding-bottom: 100px;
  // background-color: #2b6e80 20%;
  // background-image: radial-gradient(farthest-side ellipse at 10% 0, #2b6e80 20%, #0c3252);
  // background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  // background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  // background-attachment: fixed, fixed;
}

* {
  -webkit-overflow-scrolling: touch;
}

ol {
  padding-left: 30px;
}

.documentation {
  padding: 10px;
  margin: 10px;
}

.indent {
  padding-left: 20px;
  padding: 2px;
}

.metrc {
  background: #a7ffa7;
}

.biot {
  background: #a7e7ff;
}

a:focus {
  color: inherit;
}

a:hover {
  color: inherit;
}

.tmpt {
  // float: right;

  @include clearAfter();

  // &:after {
  //     content: "";
  //     clear: both;
  // }
}

.background {
  &__yellow {
    background-color: #2b6e80 20%;
    background-image: radial-gradient(farthest-side ellipse at 10% 0, #2b6e80 20%, #0c3252);
    background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
    background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  }
}

.txt-right {
  text-align: right;
}

.flatpickr-calendar.open {
  z-index: 100;
}
