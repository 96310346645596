.smokescreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 32, 51, 0.5);
  z-index: $popup;
  // overflow: auto;

  @include box-shadow(0, 0, 4px, rgba($black, 0.5));
  @include transition(opacity, 0.1s);

  &__white {
    background-color: rgba(11, 32, 51, 0.5);
  }

  &__transparent {
    background-color: rgba(11, 32, 51, 0.5);
  }
}
