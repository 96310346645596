.inv-conv {
  &__dd {
    padding-left: 20px;
  }

  &__desc {
    width: calc(100% - 240px);
    margin-left: 10px;
  }

  &__qty {
    width: 220px;
    margin-right: 10px;
  }

  &__cost {
    width: 100px;
    margin-right: 10px;
  }

  &__desc,
  &__qty,
  &__unit {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
