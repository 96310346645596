.c {
  float: left;
  padding: 0 15px;
}

.c-150 {
  width: 150px;

  @extend .c;
}

.c-200 {
  width: 200px;

  @extend .c;
}

.c-250 {
  width: 250px;

  @extend .c;
}

.c-300 {
  width: 300px;

  @extend .c;
}

.c-350 {
  width: 300px;

  @extend .c;
}

.c-400 {
  width: 400px;

  @extend .c;
}

.c-500 {
  width: 500px;

  @extend .c;
}
.c-600 {
  width: 600px;

  @extend .c;
}
.c-800 {
  width: 800px;

  @extend .c;
}
.c-900 {
  width: 900px;

  @extend .c;
}

// .discounts {
// 	width: calc(100% - 400px);
// 	max-width: 750px;

// 	@extend .c;
// }
