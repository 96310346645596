.bulk-register {
  &__title {
    font-size: 30px;
    padding: 0rem 1.5rem;
    font-weight: 500;
  }

  &__block {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 17px;
    padding: 2rem;
    color: rgb(119, 119, 119);
  }

  &__cash-input {
    max-width: 90% !important;
  }

  &__summary {
    padding: 1rem;
    font-weight: bold;
  }

  &__table-header {
    font-size: 18px;
    font-weight: bold;
    padding-left: 1rem;
    max-width: 96%;
    border-bottom: 2px solid darkgray;
  }

  &__registers {
    padding: 1rem;
  }

  &__register {
    padding: 0.5rem;
    margin-bottom: 0 !important;
  }

  &__price {
    padding-left: 0 !important;
  }

  &__invalid {
    border: 2px solid red;
    border-radius: 3px;
  }
}
