.plant-popup {
  &__header {
    border-bottom: 1px solid $grey-2;
  }

  &__row {
    background-color: rgba($grey-2, 0);

    @include transition(all, 0.1s);

    &:hover {
      background-color: rgba($grey-2, 1);
    }
  }

  &__desc {
    width: calc(100% - 240px);
  }

  &__prod {
    width: 240px;
  }

  &__desc,
  &__prod {
    display: inline-block;
    padding: 5px 10px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__dd {
    padding: 0 10px;
  }
}
