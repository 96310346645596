.dd {
  position: relative;
  z-index: $dd;
  // width: 220px;

  &__filter,
  &__search {
    // width: calc(100% - 24px)!important;
    width: 100%;
    // height: 38px;

    @extend .input;

    &:focus {
      outline: none;
    }

    @include placeholder {
      font-style: italic;
      color: rgba($black, 0.45);
      font-weight: 100;
    }
  }

  &__search {
    &--clear {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 38px;
      text-align: center;
      color: $grey-3;
      line-height: 38px;
      font-size: 16px;
      cursor: pointer;

      @include transition(all, 0.1s);

      &:hover {
        color: $black;
      }
    }
  }

  &__filter {
    position: absolute;
    top: -1px !important;
    left: -1px !important;
    // width: calc(100% - 24px)!important;
    // width: calc(100% - 24px);
    z-index: 22;

    // @extend .form-control;

    &--clear {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
    }

    // @include placeholder(font-style:italic; color: white; font-weight:100;);
  }

  &__block {
    position: absolute;
    top: 0;
    left: 0;
    // width: 300px;
    width: 100%;
    height: 300px;
    background-color: $white;
    z-index: 2;
    background: #fdfdfd;
    border: 1px solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    overflow: hidden;
    overflow-x: hidden;
    line-height: 28px;
    // display: none;

    // @include transition(all, .2s);
  }

  &__content {
    position: absolute;
    width: 100%;
    height: calc(100% - 38px);
    top: 38px;
    // bottom: 0;
    // background-color: $grey-1;
    overflow-y: auto;
    overflow-x: hidden;

    &--footer {
      height: calc(100% - 69px);
    }
  }

  &__row {
    position: relative;
    padding-left: 10px;
    min-height: 22px;
    line-height: 22px;
    // white-space: nowrap;
    cursor: pointer;

    // @include transition(all, .1s);

    &:hover {
      background-color: $grey-1;
    }

    &--high {
      // height: 44px;
      min-height: 44px;
      border-bottom: 1px solid $grey-2;
    }

    &--multi {
      padding-left: 30px;
    }

    &--subtitle {
      font-style: italic;
      color: rgba($black, 0.65);
    }

    &--selected {
      background-color: #80a8b3 !important;
      color: $white !important;
      border-bottom: 1px solid #80a8b3;
    }
  }

  &__checkbox {
    position: absolute !important;
    top: 0px;
    left: 10px;

    &--high {
      top: 11px;
    }
  }

  &__footer {
    // position: relative;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    // padding: 5px;
    border-top: 1px solid $grey-2;
    // line-height: 20px;
    // background-color: #80A8B3;
    // color: $white;
    padding-left: 10px;

    span {
      cursor: pointer;
      margin-right: 20px;
      line-height: 31px;
    }
  }
}
