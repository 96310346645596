.block-header {
  position: relative;
  padding: 15px 20px;

  &__title {
    color: #646464;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
  }

  &__options {
    position: absolute;
    top: 12px;
    right: 20px;
    height: 24px;
    list-style: none;

    li {
      display: inline-block;
      margin-left: 14px;
      padding: 0;
      color: rgba(#999999, 0.35);
      cursor: pointer;

      @include transition(all, 0.2s);

      &:hover {
        color: #646464;
      }
    }
  }
}
