.journal-entries {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 22px);
  // height: 300px;
  height: calc(100% - 300px);
  margin: 10px;

  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 38px;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  select {
    position: absolute;
    top: 0;
    right: 305px;
    width: 300px;
    margin-bottom: 10px;
    display: inline;
  }

  i {
    height: 40px;
    width: 40px;
    font-size: 24px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;

    @include transition(all, 0.2s, ease);

    &:hover {
      color: $green;
    }
  }

  &__list {
    height: calc(100% - 42px);
    overflow-y: auto;

    table {
      min-width: 100%;
      max-width: 100%;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__table {
    width: max-content;
    font-family: verdana, arial, sans-serif;
    font-size: 13px;
    // border: 1px solid #3A3A3A;
    border-collapse: collapse;

    th {
      width: auto;
      text-align: left;
      padding: 8px;
      color: $black;
      // border: 1px solid #3A3A3A;
      background-color: #b3b3b3;
    }

    tr {
      // border: 1px solid #3A3A3A;
      cursor: pointer;

      &:hover {
        background-color: rgba(#80a8b3, 1) !important;
        color: $white !important;
      }
    }

    tr:nth-child(even) {
      background-color: $grey-1;
    }

    tr:nth-child(odd) {
      background-color: $white;
    }

    td {
      // border: 1px solid #3A3A3A;
      padding: 8px;
    }

    &--selected {
      background-color: #80a8b3 !important;
      color: $white !important;
    }
  }
}
