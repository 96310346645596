.w {
  &-110 {
    width: 100px;
  }

  &-160 {
    width: 160px;
  }

  &-180 {
    width: 180px;
  }

  &-240 {
    width: 240px;
  }
}
