.btn-ll {
  background-color: rgba($ll-green, 0.8);
  border-color: $ll-green;

  @include transition(all, 0.3s);

  &:hover {
    background-color: rgba($ll-green, 0.95);
  }
}
