@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400italic,600,700%7COpen+Sans:300,400,400italic,600,700";
@import "https://fonts.googleapis.com/css?family=Libre+Franklin:300,300i,400,500,500i,700|Inconsolata";
@import "https://fonts.googleapis.com/css?family=Roboto|Lobster";
@import "https://fonts.googleapis.com/css?family=Kanit";
.hidden {
  opacity: 0;
}

.ss-pro, body, input, .table table th {
  font-family: Source Sans Pro, sans-serif;
}

.open-sans, .widget__float-input--label, .form-control__label, .drf__label, .cal-header {
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}

.libre-light {
  font-family: Libre Franklin, sans-serif;
  font-weight: 300;
}

.libre {
  font-family: Libre Franklin, sans-serif;
  font-weight: 400;
}

.libre-medium {
  font-family: Libre Franklin, sans-serif;
  font-weight: 500;
}

.libre-medium-i {
  font-family: Libre Franklin, sans-serif;
  font-style: italic;
  font-weight: 500;
}

.libre-bold, .notification-bar__body {
  font-family: Libre Franklin, sans-serif;
  font-weight: 700;
}

.mono {
  font-family: Inconsolata, sans-serif;
}

.header {
  width: calc(100% - 260px);
  height: 75px;
  z-index: 2;
  -o-transition: all .2s ease-in-out;
  background-color: #fff;
  border-color: #f5f5f5e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 260px;
  box-shadow: 0 2px 10px #00000080;
}

.header__test {
  background-color: pink;
}

.header__options {
  color: #ffffffb3;
  font-size: 26px;
  line-height: 60px;
  position: absolute;
  top: 0;
  right: 0;
}

.header__options i {
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  padding-right: 20px;
  transition: all .1s ease-in-out;
}

.header__options i:hover {
  color: #fff;
}

.header__button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  color: #000000bf;
  -o-transition: all .1s ease-in-out;
  background-color: #d0d0e180;
  border: 1px solid #d0d0e1cc;
  border-radius: 3px;
  margin-left: 20px;
  line-height: 40px;
  transition: all .1s ease-in-out;
  display: inline-block;
}

.header__button:hover {
  color: #000;
  box-shadow: 0 0 8px #d0d0e1;
}

.header__loc {
  position: absolute;
  top: 5px;
  left: 80px;
}

.header__loc select {
  border: 0;
  outline: 0;
}

.header__loc--location {
  position: absolute;
  top: 25px;
  left: 0;
}

.header__logo {
  position: absolute;
  top: 5px;
  right: 10px;
}

.header__logo img {
  height: 60px;
  opacity: .75;
}

.lsp-loc {
  height: 100%;
  z-index: 0;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 20px;
  left: 20px;
}

.lsp-loc__tablet {
  top: 10px;
  left: 80px;
}

.lsp-loc__lsp {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.lsp-loc__loc {
  color: #ffffffb3;
  font-size: 14px;
  font-weight: 300;
}

.lsp-loc__lsp span, .lsp-loc__loc span {
  cursor: pointer;
}

.lsp-loc__block {
  min-width: 200px;
  max-height: calc(100% - 80px);
  opacity: 0;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #0003;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  transition: opacity .2s ease-in-out;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 6px 12px #0000002d;
}

.lsp-loc__block--item {
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
}

.company-name {
  color: #fff;
  opacity: .1;
  font-family: Kanit, sans-serif;
  font-size: 64px;
  position: fixed;
  bottom: 0;
  right: 30px;
}

.main {
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.main__container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  position: relative;
  overflow-y: auto;
}

.sidebar {
  width: 260px;
  height: 100%;
  color: #fff;
  z-index: 3;
  -o-transition: left .2s ease-in-out;
  background-color: #2c343f;
  transition: left .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar__content {
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  overflow: auto;
}

.sidebar__logo {
  width: 100%;
  height: auto;
  position: relative;
}

.sidebar__logo img {
  width: 100%;
}

.app {
  height: 100%;
  -o-transition: all .2s ease-in-out;
  background-color: #2b6e80 20%;
  background-attachment: fixed, fixed;
  padding: 75px 0 0 260px;
  transition: all .2s ease-in-out;
  position: relative;
}

.app__tablet {
  padding: 75px 0 0;
}

.app__yellow {
  background-color: #414e60 20%;
  background-image: radial-gradient(farthest-side at 10% 0, #414e60 20%, #94a3b1);
  background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);
  background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #414e60 20%, #94a3b1);
}

.auth {
  width: 100%;
  height: 100%;
  z-index: 8;
  top: 0;
  left: 0;
  position: fixed !important;
}

.auth__background {
  width: 100%;
  height: 100%;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  background-image: url("auth.6d4b6134.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.auth__content {
  width: 500px;
  height: 460px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
  box-shadow: 0 0 18px #000;
}

.auth__content--logo {
  text-align: center;
  padding: 20px 0 40px;
}

.auth__content--logo img {
  width: 320px;
}

.auth__content--right {
  float: right;
  background-color: #fff;
  padding-top: 20px;
  padding-right: 10px;
}

.auth p {
  padding: 10px 0 20px;
}

.auth__go {
  width: 60px;
  margin-top: 10px;
}

.auth__reset {
  width: 90px;
  margin-top: 10px;
}

.auth__message {
  color: red;
  font-size: 16px;
  position: absolute;
  bottom: 30px;
  right: 80px;
}

.auth__version {
  color: #ffffff80;
  z-index: 22;
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.auth input {
  width: 100%;
  -o-transition: all .2s ease-in-out;
  background: #f8f8f8;
  border: 1px solid #00b626cc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 12px 15px;
  font-size: 16px;
  transition: all .2s ease-in-out;
  color: #383838 !important;
}

.auth input:focus {
  border-color: #00b62699;
  outline-width: 0;
}

.auth__error {
  background-color: #ff00004d !important;
}

.block-content {
  max-width: 100%;
  -o-transition: opacity .2s ease-in-out;
  margin: 0 auto;
  padding: 20px 20px 1px;
  transition: opacity .2s ease-in-out;
  position: relative;
  overflow-x: visible;
}

.block-header {
  padding: 15px 20px;
  position: relative;
}

.block-header__title {
  color: #646464;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}

.block-header__options {
  height: 24px;
  list-style: none;
  position: absolute;
  top: 12px;
  right: 20px;
}

.block-header__options li {
  color: #99999959;
  cursor: pointer;
  -o-transition: all .2s ease-in-out;
  margin-left: 14px;
  padding: 0;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.block-header__options li:hover {
  color: #646464;
}

.block-spinner {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 999;
  text-align: center;
  -o-transition: opacity .3s ease-in-out;
  background-color: #ffffffe6;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.block-spinner img {
  width: 200px;
  position: relative;
  top: -60px;
}

.block-spinner i {
  text-align: center;
  color: #000000bf;
  font-size: 24px;
}

.block-spinner__narrow {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  margin-left: 20px;
}

.block {
  background-color: #fff;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 2px 10px #3838380d;
}

.block__content {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px 1px;
  overflow: hidden;
}

.full-screen-spin {
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  position: fixed;
  inset: 0;
}

.bg-primary-dark {
  background-color: #3e4a59;
}

.text-white-op {
  color: #ffffffd9;
}

.multi-dimension-table-report {
  white-space: nowrap;
  overflow-x: auto;
}

.multi-dimension-table-report table thead {
  border: 5px solid #f5f5f5;
  border-bottom-width: 0;
}

.multi-dimension-table-report table tbody {
  border: 5px solid #f5f5f5;
  border-top-width: 0;
}

.y-dimension-container {
  vertical-align: top;
  margin-right: 20px;
  display: inline-block;
}

.multi-dimension-table-report td, .multi-dimension-table-report th {
  border: 2px solid #f5f5f5;
  padding: 1px;
}

.y-header, .x-header {
  text-align: center;
}

.c, .c-900, .c-800, .c-600, .c-500, .c-400, .c-350, .c-300, .c-250, .c-200, .c-150 {
  float: left;
  padding: 0 15px;
}

.c-150 {
  width: 150px;
}

.c-200 {
  width: 200px;
}

.c-250 {
  width: 250px;
}

.c-300, .c-350 {
  width: 300px;
}

.c-400 {
  width: 400px;
}

.c-500 {
  width: 500px;
}

.c-600 {
  width: 600px;
}

.c-800 {
  width: 800px;
}

.c-900 {
  width: 900px;
}

.w-110 {
  width: 100px;
}

.w-160 {
  width: 160px;
}

.w-180 {
  width: 180px;
}

.w-240 {
  width: 240px;
}

.journal-entries {
  width: calc(100% - 22px);
  height: calc(100% - 300px);
  margin: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.journal-entries input {
  width: 300px;
  height: 38px;
  margin-bottom: 10px;
  padding-left: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.journal-entries select {
  width: 300px;
  margin-bottom: 10px;
  display: inline;
  position: absolute;
  top: 0;
  right: 305px;
}

.journal-entries i {
  height: 40px;
  width: 40px;
  cursor: pointer;
  text-align: center;
  -o-transition: all .2s ease;
  font-size: 24px;
  line-height: 40px;
  transition: all .2s;
}

.journal-entries i:hover {
  color: #00b626;
}

.journal-entries__list {
  height: calc(100% - 42px);
  overflow-y: auto;
}

.journal-entries__list table {
  min-width: 100%;
  max-width: 100%;
}

.journal-entries__list td {
  white-space: nowrap;
  overflow: hidden;
}

.journal-entries__table {
  width: max-content;
  border-collapse: collapse;
  font-family: verdana, arial, sans-serif;
  font-size: 13px;
}

.journal-entries__table th {
  width: auto;
  text-align: left;
  color: #000;
  background-color: #b3b3b3;
  padding: 8px;
}

.journal-entries__table tr {
  cursor: pointer;
}

.journal-entries__table tr:hover {
  color: #fff !important;
  background-color: #80a8b3 !important;
}

.journal-entries__table tr:nth-child(2n) {
  background-color: #f5f5f5;
}

.journal-entries__table tr:nth-child(2n+1) {
  background-color: #fff;
}

.journal-entries__table td {
  padding: 8px;
}

.journal-entries__table--selected {
  color: #fff !important;
  background-color: #80a8b3 !important;
}

.journal__subject {
  margin-bottom: 10px;
}

.journal__body {
  height: 120px;
}

.add-lot {
  width: 500px;
}

.add-lot button {
  width: 130px;
}

.batch-header {
  color: #000;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}

.batch-header span {
  color: #000;
}

.plant-mover__desc {
  width: 100%;
}

.plant-mover__type {
  width: 240px;
}

.plant-mover__desc, .plant-mover__prod, .plant-mover__type {
  padding: 5px 10px;
  display: inline-block;
}

.plant-mover__desc span, .plant-mover__prod span, .plant-mover__type span {
  font-size: 16px;
  font-weight: 600;
}

.plant-popup__header {
  border-bottom: 1px solid #ddd;
}

.plant-popup__row {
  -o-transition: all .1s ease-in-out;
  background-color: #ddd0;
  transition: all .1s ease-in-out;
}

.plant-popup__row:hover {
  background-color: #ddd;
}

.plant-popup__desc {
  width: calc(100% - 240px);
}

.plant-popup__prod {
  width: 240px;
}

.plant-popup__desc, .plant-popup__prod {
  padding: 5px 10px;
  display: inline-block;
}

.plant-popup__desc span, .plant-popup__prod span {
  font-size: 16px;
  font-weight: 600;
}

.plant-popup__dd {
  padding: 0 10px;
}

.faq__item {
  margin: 10px 0;
}

.faq__block {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.faq__title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.faq__body {
  height: 0;
  -o-transition: all .2s ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  transition: all .2s ease-in-out;
  overflow: hidden;
}

.faq__body h1 {
  font-size: 16px;
}

.faq__body p {
  margin-bottom: 20px;
}

.button, .widget__buttons--right-warning, .widget__buttons--left-warning, .widget__buttons--right-danger, .widget__buttons--left-danger, .widget__buttons--std, .widget__buttons--left, .widget__buttons--right {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  -o-transition: all .2s ease-in-out;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.button__success, .widget__buttons--std, .widget__buttons--left, .widget__buttons--right {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.button__success:hover, .widget__buttons--std:hover, .widget__buttons--left:hover, .widget__buttons--right:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.button__danger, .widget__buttons--right-danger, .widget__buttons--left-danger {
  color: #fff;
  background-color: red;
  border-color: red;
}

.button__danger:hover, .widget__buttons--right-danger:hover, .widget__buttons--left-danger:hover {
  color: #fff;
  background-color: #be0000;
  border-color: #be0000;
}

.button__warning, .widget__buttons--right-warning, .widget__buttons--left-warning {
  color: #fff;
  background-color: #f7dc40;
  border-color: #f7dc40;
}

.button__warning:hover, .widget__buttons--right-warning:hover, .widget__buttons--left-warning:hover {
  color: #fff;
  background-color: #dbc648;
  border-color: #dbc648;
}

.daniel {
  color: #fff;
}

.pull-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cal-header {
  width: 100%;
  height: 30px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  position: relative;
  top: 0;
  left: 0;
}

.cal-header__first span {
  cursor: pointer;
}

.cal-month {
  width: 280px;
  height: calc(100% - 50px);
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.cal-month__ddd, .cal-month__day {
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  line-height: 40px;
}

.cal-month__ddd {
  text-transform: uppercase;
  font-size: 12px;
}

.cal-month__day {
  font-size: 15px;
}

.cal-month__today {
  background-color: #d0d0e14d;
  border-radius: 50%;
}

.cal-month__before-today {
  color: #9494b8;
  cursor: default;
}

.cal-month__selected {
  border-radius: 50%;
  padding: -2px;
  box-shadow: 0 0 0 3px #f5ad64;
}

.cal-todo {
  width: 150px;
  height: 100%;
  background-color: #3e454d;
  position: absolute;
  top: 0;
  right: 0;
}

.cal-todo__header {
  color: #f4a142;
  text-align: center;
  margin-top: 30px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
}

.calendar {
  position: relative;
}

.calendar__content {
  width: 300px;
  height: 350px;
  opacity: 0;
  z-index: 999999;
  background-color: #fff;
  border: 1px solid #00000040;
  border-radius: 4px;
  position: absolute;
  top: 40px;
  left: 0;
  box-shadow: 0 0 20px #d0d0e1;
}

.calendar__prev, .calendar__next {
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 25px;
  position: absolute;
  top: 2px;
}

.calendar__prev {
  left: 10px;
}

.calendar__next {
  right: 10px;
}

.calendar__close {
  position: absolute;
  top: -10px;
  right: -10px;
}

.calendar__date {
  text-align: right;
  outline: none;
}

.calendar__date--active {
  box-shadow: 0 0 10px #f5ad64;
}

.calendar__date_left {
  text-align: left;
  outline: none;
}

.calendar__date_left--active {
  box-shadow: 0 0 10px #f5ad64;
}

.calendar__clear {
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
}

.calendar__overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.calendar__overlay--close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.calendar__overlay--year {
  margin: 80px 20px 20px;
  position: relative;
}

.calendar__overlay--year select {
  width: 100% !important;
}

.calendar__overlay--month {
  width: 50%;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  display: inline-block;
}

.dd-search {
  z-index: 2;
  position: relative;
}

.dd-search__block {
  width: 300px;
  height: 300px;
  z-index: 2;
  -o-transition: all .2s ease-in-out;
  background: #fdfdfd;
  border: 1px solid #c8cee7;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  overflow: hidden;
}

.dd-search__list {
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.dd-search__list--item {
  width: 100%;
  color: #000;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  padding: 4px 10px;
  transition: all .1s ease-in-out;
}

.dd-search__list--item:hover, .dd-search__list--selected {
  color: #fff;
  background-color: #80a8b3;
}

.dd-search__refresh {
  width: 100%;
  height: 30px;
  border-top: 1px solid #80a8b3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.dd {
  z-index: 8;
  position: relative;
}

.dd__filter, .dd__search {
  width: 100%;
}

.dd__filter:focus, .dd__search:focus {
  outline: none;
}

.dd__filter::-webkit-input-placeholder, .dd__search::-webkit-input-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.dd__filter:-moz-placeholder, .dd__search:-moz-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.dd__filter::-moz-placeholder, .dd__search::-moz-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.dd__filter:-ms-input-placeholder, .dd__search:-ms-input-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.dd__search--clear {
  width: 32px;
  height: 38px;
  text-align: center;
  color: #606060;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  font-size: 16px;
  line-height: 38px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

.dd__search--clear:hover {
  color: #000;
}

.dd__filter {
  z-index: 22;
  position: absolute;
  top: -1px !important;
  left: -1px !important;
}

.dd__filter--clear {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}

.dd__block {
  width: 100%;
  height: 300px;
  z-index: 2;
  background: #fdfdfd;
  border: 1px solid #c8cee7;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  border-radius: 3px;
  line-height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dd__content {
  width: 100%;
  height: calc(100% - 38px);
  position: absolute;
  top: 38px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dd__content--footer {
  height: calc(100% - 69px);
}

.dd__row {
  min-height: 22px;
  cursor: pointer;
  padding-left: 10px;
  line-height: 22px;
  position: relative;
}

.dd__row:hover {
  background-color: #f5f5f5;
}

.dd__row--high {
  min-height: 44px;
  border-bottom: 1px solid #ddd;
}

.dd__row--multi {
  padding-left: 30px;
}

.dd__row--subtitle {
  color: #000000a6;
  font-style: italic;
}

.dd__row--selected {
  border-bottom: 1px solid #80a8b3;
  color: #fff !important;
  background-color: #80a8b3 !important;
}

.dd__checkbox {
  top: 0;
  left: 10px;
  position: absolute !important;
}

.dd__checkbox--high {
  top: 11px;
}

.dd__footer {
  width: 100%;
  height: 30px;
  border-top: 1px solid #ddd;
  padding-left: 10px;
  position: absolute;
  bottom: 0;
}

.dd__footer span {
  cursor: pointer;
  margin-right: 20px;
  line-height: 31px;
}

.select-2 {
  height: 30px !important;
}

.select__single-row .col-6 {
  padding: 0;
}

.select__single-row .form-control__label {
  line-height: 38px;
}

.checkbox {
  width: 20px;
  height: 20px;
  position: relative;
}

.checkbox__label {
  cursor: pointer;
  white-space: nowrap;
  margin-left: 7px;
  position: absolute;
}

.checkbox__click {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.input-2, .calendar__overlay--year select, .select-2 {
  width: 144px;
  height: 30px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  top: 0;
  left: 32px;
  box-shadow: inset 0 1px 1px #00000013;
}

.input-text, .graph-by-month__graph-type, .date-time__time, .select {
  position: relative;
}

.input-text__action {
  width: 50px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  color: #606060;
  -o-transition: all .1s ease-in-out;
  background-color: #ddd;
  border: 1px solid #00000026;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
}

.input-text__action:hover {
  color: #000;
}

.input-text__action i {
  font-size: 20px;
  line-height: 38px;
}

.input-text__action--left {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  left: 0;
}

.input-text__action--right {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  right: 0;
}

.input-text__label {
  color: #000;
  font-size: 16px;
  line-height: 32px;
}

.input, .dd__filter, .dd__search {
  height: 38px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  top: 0;
  left: 32px;
  box-shadow: inset 0 1px 1px #00000013;
}

ul.autocomplete {
  width: 100%;
  z-index: 100;
  max-height: 500px;
  background: #fff;
  border: 1px solid #000;
  margin: auto;
  list-style: none;
  transition: width .3s;
  display: block;
  position: absolute;
  overflow-y: scroll;
}

ul.autocomplete li {
  width: 100%;
  z-index: 100;
  cursor: pointer;
  padding: .25rem;
  font-size: 1rem;
  display: block;
}

ul.autocomplete li.autocomplete-active-option, ul.autocomplete li:hover {
  background: #80808024;
}

input.autocomplete {
  position: relative;
}

.drf {
  display: inline-block;
}

.drf__label {
  font-color: #646464;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 18.6px;
}

.drf__label span {
  color: red;
}

.drf__clear {
  display: block;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.sr {
  margin: 0 0 10px;
}

.sr__label {
  padding: 0;
  position: relative;
}

.sr__label--title {
  width: 96%;
  box-sizing: border-box;
  padding-top: 1em;
  position: relative;
}

.sr__label--title span {
  color: red;
}

.sr__content {
  box-sizing: border-box;
  padding: 0;
  line-height: 38px;
}

.sr__picklist {
  padding-top: 20px;
  line-height: 2;
}

.sr__right {
  float: right;
}

.srtxt {
  min-height: 24px;
  margin: 0;
}

.srtxt__label {
  padding: 0;
  position: relative;
}

.srtxt__label--title {
  width: 96%;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.srtxt__label--title span {
  color: red;
}

.srtxt__content {
  box-sizing: border-box;
  padding: 0;
  line-height: 20px;
  position: relative;
}

.srtxt__content--txt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timepicker {
  position: relative;
}

.timepicker__content {
  width: 286px;
  height: 350px;
  opacity: 0;
  z-index: 999999;
  background-color: #fff;
  border: 1px solid #00000040;
  border-radius: 4px;
  position: absolute;
  top: 45px;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 20px #d0d0e1;
}

.timepicker__header {
  width: 100%;
  height: 60px;
  color: #fff;
  text-align: center;
  background-color: #3e4a59;
  font-size: 26px;
  line-height: 60px;
  position: relative;
}

.timepicker__header--am, .timepicker__header--pm {
  height: 60px;
  color: #606060;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.timepicker__header--am {
  left: 20px;
}

.timepicker__header--pm {
  right: 20px;
}

.timepicker__header--selected {
  color: #fff;
}

.timepicker__table {
  width: 250px;
  height: 250px;
  color: #000;
  background-color: #ddd;
  border-radius: 50%;
  margin: 20px auto 0;
  position: relative;
  box-shadow: 0 0 20px #d0d0e1;
}

.timepicker__table--val {
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  border-radius: 50%;
  font-family: Inconsolata, sans-serif;
  font-size: 24px;
  line-height: 32px;
  transition: all .1s ease-in-out;
  position: absolute;
}

.timepicker__table--val:hover, .timepicker__table--selected {
  color: #fff;
  background-color: #606060;
}

.Select {
  position: relative;
}

.Select input::-webkit-contacts-auto-fill-button, .Select input::-webkit-credentials-auto-fill-button {
  display: none !important;
}

.Select input::-ms-clear {
  display: none !important;
}

.Select input::-ms-reveal {
  display: none !important;
}

.Select, .Select div, .Select input, .Select span {
  box-sizing: border-box;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}

.Select.is-disabled > .Select-control {
  background-color: inherit;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-searchable.is-open > .Select-control, .Select.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.Select.is-open > .Select-control {
  background: #fff;
  border-color: #00000026 #00000026 #0d0d0d26;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Select.is-open > .Select-control .Select-arrow {
  border-width: 0 4px 4px;
  border-color: #0000 #0000 #000;
  top: -2px;
}

.Select.is-focused > .Select-control {
  background: inherit;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: inherit;
  border-color: #5cb3fd #75bffd #75bffd;
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 24px;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: inherit;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: inherit;
  outline: none;
  text-decoration: underline;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background: inherit;
}

.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: inherit;
}

.Select.Select--rtl {
  direction: rtl;
  text-align: right;
}

.Select-control {
  color: inherit;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  height: 24px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: .25rem;
  outline: none;
  display: table;
  position: relative;
  overflow: hidden;
}

.Select-control:hover {
  box-shadow: inherit;
}

.Select-control .Select-input:focus {
  background: inherit;
  outline: none;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  color: inherit;
  padding-left: inherit;
  padding-right: inherit;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.Select-input {
  height: 20px;
  padding-left: inherit;
  padding-right: inherit;
  vertical-align: middle;
}

.Select-input > input {
  width: 100%;
  box-shadow: none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  line-height: 17px;
  display: inline-block;
}

.is-focused .Select-input > input {
  cursor: text;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 30px;
  display: table-cell;
  position: relative;
}

.Select-loading {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: 3.75px solid inherit;
  border-right-color: inherit;
  vertical-align: middle;
  border-radius: 50%;
  animation: .4s linear infinite Select-animation-spin;
  display: inline-block;
  position: relative;
}

.Select-clear-zone {
  color: inherit;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 4px;
  animation: .2s Select-animation-fadeIn;
  display: table-cell;
  position: relative;
}

.Select-clear-zone:hover {
  color: inherit;
}

.Select-clear {
  font-size: inherit;
  line-height: 1;
  display: inline-block;
}

.Select--multi .Select-clear-zone {
  width: 4px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  height: 1px;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  float: left;
  margin: -1px;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

.Select-arrow-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  padding-right: 4px;
  display: table-cell;
  position: relative;
}

.Select--rtl .Select-arrow-zone {
  padding-left: 4px;
  padding-right: 0;
}

.Select-arrow {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top-color: #000;
  border-bottom-width: 2px;
  display: inline-block;
  position: relative;
}

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select-menu-outer {
  box-shadow: inherit;
  box-sizing: border-box;
  max-height: 200px;
  width: 100%;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #00000026;
  border-top-color: #ececec93;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: 0;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  padding: inherit inherit;
  display: block;
}

.Select-option:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.Select-option.is-selected, .Select-option.is-focused {
  background-color: inherit;
  color: inherit;
}

.Select-option.is-disabled {
  color: inherit;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: inherit inherit;
  display: block;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: inherit;
  padding: 0;
}

.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: inherit;
}

.Select--multi.has-value .Select-input {
  margin-left: 2px;
}

.Select--multi .Select-value {
  color: #464a4c;
  vertical-align: top;
  background-color: #00000026;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin-top: 2px;
  margin-left: 2px;
  font-family: sans-serif;
  font-size: .8rem;
  line-height: 20px;
  display: inline-block;
}

.Select--multi .Select-value-icon, .Select--multi .Select-value-label {
  vertical-align: middle;
  display: inline-block;
}

.Select--multi .Select-value-label {
  cursor: default;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  padding: 1px 5px;
  line-height: 20px;
}

.Select--multi a.Select-value-label {
  color: #464a4c;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-right: 1px solid #00000026;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  padding: 0 5px 2px;
}

.Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
  background-color: inherit;
  color: inherit;
}

.Select--multi .Select-value-icon:active {
  background-color: #00000026;
}

.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 2px;
}

.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #00000026;
  border-right: none;
}

.Select--multi.is-disabled .Select-value {
  background-color: inherit;
  border: 1px solid inherit;
  color: inherit;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid inherit;
}

.Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
  background-color: inherit;
}

.btn-disabled {
  color: #383838 !important;
  background-color: #38383899 !important;
  border-color: #ddd !important;
}

.btn-green, .widget .increase-btn {
  height: 40px;
  color: #0000008c;
  text-align: center;
  cursor: pointer;
  -o-transition: all .15s ease;
  background: -o-linear-gradient(top, #a9ea9f99 0%, #a9ea9fe6 100%);
  background: -ms-linear-gradient(top, #a9ea9f99 0%, #a9ea9fe6 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#a9ea9f99 0%, #a9ea9fe6 100%);
  border: 1px solid #02990040;
  border-bottom-color: #028f0040;
  border-radius: 4px;
  padding: 0 12px;
  font-family: Verdana, Lato, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  transition: all .15s;
  display: inline-block;
  box-shadow: 0 1px 14px #0000001a;
}

.btn-green:hover, .widget .increase-btn:hover {
  color: #000000e6;
  background: -o-linear-gradient(top, #a9ea9fe6 0%, #a9ea9f 100%);
  background: -ms-linear-gradient(top, #a9ea9fe6 0%, #a9ea9f 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#a9ea9fe6 0%, #a9ea9f 100%);
}

.btn-green__disabled {
  color: #38383899;
  background: -o-linear-gradient(top, #f5f5f5e6 0%, #dddddde6 100%);
  background: -ms-linear-gradient(top, #f5f5f5e6 0%, #dddddde6 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#f5f5f5e6 0%, #dddddde6 100%);
  border-color: #f5f5f5 #ddd;
}

.btn-green__disabled:hover {
  color: #000000b3;
  background: -o-linear-gradient(top, #f5f5f5 0%, #ddd 100%);
  background: -ms-linear-gradient(top, #f5f5f5 0%, #ddd 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#f5f5f5 0%, #ddd 100%);
}

.btn-blue {
  height: 40px;
  color: #0000008c;
  text-align: center;
  cursor: pointer;
  -o-transition: all .15s ease;
  background: -o-linear-gradient(top, #5eb6d199 0%, #5eb6d1e6 100%);
  background: -ms-linear-gradient(top, #5eb6d199 0%, #5eb6d1e6 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#5eb6d199 0%, #5eb6d1e6 100%);
  border: 1px solid #80a8b340;
  border-radius: 4px;
  padding: 0 12px;
  font-family: Verdana, Lato, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  transition: all .15s;
  display: inline-block;
  box-shadow: 0 1px 14px #0000001a;
}

.btn-blue:hover {
  color: #000000e6;
  background: -o-linear-gradient(top, #5eb6d1e6 0%, #5eb6d1 100%);
  background: -ms-linear-gradient(top, #5eb6d1e6 0%, #5eb6d1 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#5eb6d1e6 0%, #5eb6d1 100%);
}

.btn-blue__disabled {
  background: -o-linear-gradient(top, #f5f5f5e6 0%, #dddddde6 100%);
  background: -ms-linear-gradient(top, #f5f5f5e6 0%, #dddddde6 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#f5f5f5e6 0%, #dddddde6 100%);
  color: #38383899 !important;
  border-color: #f5f5f5 #ddd !important;
}

.btn-blue__disabled:hover {
  background: -o-linear-gradient(top, #f5f5f5 0%, #ddd 100%);
  background: -ms-linear-gradient(top, #f5f5f5 0%, #ddd 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#000000", GradientType= 0);
  background: linear-gradient(#f5f5f5 0%, #ddd 100%);
  color: #000000b3 !important;
}

.btn-ll {
  -o-transition: all .3s ease-in-out;
  background-color: #06b12ccc;
  border-color: #06b12c;
  transition: all .3s ease-in-out;
}

.btn-ll:hover {
  background-color: #06b12cf2;
}

.btn {
  cursor: pointer;
}

.btn__wide {
  width: 100% !important;
  margin: 0 !important;
}

.btn__80 {
  width: 80px;
  max-width: 80px !important;
}

.btn__120 {
  width: 120px;
  max-width: 120px !important;
}

.btn-pad {
  margin: 0 5px;
}

.btn-row {
  height: 50px;
  margin-top: 20px;
}

.date-time {
  position: relative;
}

.date-time__calendar {
  width: calc(100% - 100px) !important;
}

.date-time__time {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px !important;
}

.form-control {
  resize: none;
  box-sizing: border-box;
}

.form-control::-webkit-input-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.form-control:-moz-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.form-control::-moz-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.form-control:-ms-input-placeholder {
  color: #00000073;
  font-style: italic;
  font-weight: 100;
}

.form-control__label {
  font-color: #646464;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 18.6px;
}

.form-control__label span {
  color: red;
}

.form-control[readonly] {
  background-color: #fff;
}

.invalid {
  border: 1px solid red;
  border-radius: 3px;
}

.invalid:focus {
  border: 1px solid #ff4242;
  box-shadow: 0 0 3px red;
}

.switch {
  width: 4.5rem;
  height: calc(2.25rem + 2px);
  border: 1px solid #00000026;
  border-radius: .25rem;
  display: inline-block;
  position: relative;
}

.switch input {
  display: none;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  height: calc(2.25rem - 8px);
  width: calc(2.25rem - 8px);
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider:before {
  left: 10px;
}

input:checked + .slider {
  background-color: #2c343f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2c343f;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.data-load-card {
  height: 300px;
  width: 250px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: .5rem;
  margin: 1rem;
  display: inline-block;
  overflow: scroll;
  box-shadow: 0 0 16px #d0d0e1;
}

.data-load-card-header {
  background: #fff;
  border-bottom: 1px solid #000;
  padding: 1rem;
  position: sticky;
  top: 0;
}

.data-load-card-row {
  border-bottom: 1px solid #d3d3d3;
  padding: .25rem 0 .25rem .3rem;
}

.data-load-card-row:last-child {
  border-bottom: none;
}

.data-load-item-title {
  width: 80%;
  display: inline-block;
}

.data-load-item-rows {
  width: 20%;
  display: inline-block;
}

.canceled {
  color: #8b0000;
  font-weight: 400;
}

.completed {
  color: green;
  font-weight: 400;
}

.graph-by-month {
  position: relative;
}

.graph-by-month__canvas {
  width: 100%;
}

.graph-by-month__controls {
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
  padding: 10px 0;
  position: relative;
}

.graph-by-month__controls--change {
  cursor: pointer;
  margin-left: 30px;
  display: inline-block;
}

.graph-by-month__controls--change i {
  padding: 0 10px;
}

.graph-by-month__controls--divider {
  width: 1px;
  height: 20px;
  background-color: #383838;
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.graph-by-month__graph-type {
  width: 300px;
}

img.rotate-90 {
  transform: rotate(90deg);
}

img.rotate-180 {
  transform: rotate(180deg);
}

img.rotate-270 {
  transform: rotate(270deg);
}

.inv-add__header {
  padding: 10px;
}

.inv-add__desc {
  width: calc(100% - 160px);
}

.inv-add__qty {
  width: 160px;
}

.inv-add__desc, .inv-add__qty, .inv-add__unit {
  padding: 5px 10px;
  display: inline-block;
}

.inv-add__desc span, .inv-add__qty span, .inv-add__unit span {
  font-size: 16px;
  font-weight: 600;
}

.inv-add__row--details {
  border-bottom: 1px solid #ddd;
  padding: 0 10px;
}

.inv-comb__desc {
  width: calc(100% - 320px);
}

.inv-comb__qty, .inv-comb__unit {
  width: 160px;
}

.inv-comb__desc, .inv-comb__qty, .inv-comb__unit {
  padding: 5px 10px;
  display: inline-block;
}

.inv-comb__desc span, .inv-comb__qty span, .inv-comb__unit span {
  font-size: 16px;
  font-weight: 600;
}

.inv-conv__dd {
  padding-left: 20px;
}

.inv-conv__desc {
  width: calc(100% - 240px);
  margin-left: 10px;
}

.inv-conv__qty {
  width: 220px;
  margin-right: 10px;
}

.inv-conv__cost {
  width: 100px;
  margin-right: 10px;
}

.inv-conv__desc, .inv-conv__qty, .inv-conv__unit {
  padding: 5px 10px;
  display: inline-block;
}

.inv-conv__desc span, .inv-conv__qty span, .inv-conv__unit span {
  font-size: 16px;
  font-weight: 600;
}

.inv-printer__desc {
  width: calc(100% - 120px);
}

.inv-printer__qty {
  width: 120px;
}

.inv-printer__desc, .inv-printer__qty {
  padding: 5px 10px;
  display: inline-block;
}

.inv-printer__desc span, .inv-printer__qty span {
  font-size: 16px;
  font-weight: 600;
}

.inv-popup__header {
  border-bottom: 1px solid #ddd;
}

.inv-popup__row {
  -o-transition: all .1s ease-in-out;
  background-color: #ddd0;
  transition: all .1s ease-in-out;
}

.inv-popup__row:hover {
  background-color: #ddd;
}

.inv-popup__desc {
  width: calc(100% - 240px);
}

.inv-popup__qty {
  width: 240px;
}

.inv-popup__desc, .inv-popup__qty {
  padding: 5px 10px;
  display: inline-block;
}

.inv-popup__desc span, .inv-popup__qty span {
  font-size: 16px;
  font-weight: 600;
}

.inv-popup__dd {
  padding: 0 10px;
}

.inv-mover__desc {
  width: calc(100% - 240px);
}

.inv-mover__qty {
  width: 240px;
}

.inv-mover__desc, .inv-mover__qty {
  padding: 5px 10px;
  display: inline-block;
}

.inv-mover__desc span, .inv-mover__qty span {
  font-size: 16px;
  font-weight: 600;
}

.inventory__input {
  width: 160px;
  margin: 0 20px 10px 0;
  display: inline-block;
}

.inventory-bulk-action .package-list {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

#upload-button-container > .upload-button {
  margin-bottom: 0;
  display: inline-block;
}

#upload-button-container > .upload-button > label {
  margin-bottom: 0;
  margin-right: 20px;
}

.user-permissions {
  position: relative;
}

.user-permissions__title {
  height: 30px;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
  line-height: 30px;
  transition: all .1s ease-in-out;
}

.user-permissions__title:hover {
  background-color: #f5f5f5;
}

.user-permissions__count {
  position: absolute;
  top: 0;
  right: 0;
}

.permission-module {
  position: relative;
}

.permission-module__title {
  height: 30px;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
  line-height: 30px;
  transition: all .1s ease-in-out;
}

.permission-module__checkbox {
  padding: 4px 10px;
}

.permission-module__count {
  position: absolute;
  top: 0;
  right: 0;
}

.customer-journal__item {
  min-height: 30px;
  cursor: pointer;
  -o-transition: all .1s ease-in-out;
  border-bottom: 1px solid #f5f5f5;
  line-height: 30px;
  transition: all .1s ease-in-out;
}

.customer-journal__item:hover {
  background-color: #f5f5f5;
}

.customer-journal__subject {
  margin-bottom: 10px;
}

.customer-journal__body {
  height: 120px;
  margin-bottom: 10px;
}

.customer-journal__filter {
  width: 144px;
  height: 30px;
  font-size: 13px;
}

.message {
  height: 30px;
}

.timeclock__header {
  border-bottom: 1px solid #d0d0e1;
}

.timeclock__header td:first-child {
  width: 300px;
}

.timeclock__header td:nth-child(2), .timeclock__header td:nth-child(3) {
  width: 250px;
}

.timeclock__border {
  border-bottom: 1px solid #d0d0e1;
}

.timeclock__name {
  padding-top: 20px;
}

.timeclock__time {
  margin: 0 4px 0 10px;
  font-size: 16px;
}

.timeclock__table {
  cursor: pointer;
}

.timeclock__table:hover {
  background-color: #d0d0e140 !important;
}

.timeclock__edit {
  margin-left: 10px;
  font-style: italic;
}

.description-table {
  width: 450px;
}

.token-field {
  width: 150px;
}

.label-container div::-webkit-scrollbar-track {
  background-color: #f3f5f6;
}

.label-container div::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #34d2af;
}

.label-container div::-webkit-scrollbar-thumb {
  background-color: #34d2af;
  border-radius: 1.5px;
}

.note {
  height: auto;
  -o-transition: all .2s ease-in-out;
  padding: 20px 20px 0;
  transition: all .2s ease-in-out;
  position: relative;
}

.note__content {
  width: 400px;
  opacity: 1;
  -o-transition: all .4s ease-in-out;
  border-radius: 6px;
  padding: 7px;
  transition: all .4s ease-in-out;
  position: relative;
  left: 0;
}

.note__content--success {
  background-color: #a9ea9f;
  border: 1px solid #a9ea9f;
  box-shadow: 0 0 16px #a9ea9fbf;
}

.note__content--error {
  color: #fff;
  background-color: #f56b6d;
  border: 1px solid #f56b6d;
  box-shadow: 0 0 16px #f56b6dbf;
}

.note__content--alert {
  color: #000;
  background-color: #ffed00;
  border: 1px solid #ffed00;
  box-shadow: 0 0 16px #ffed00bf;
}

.note__info {
  padding: 10px;
}

.note__info--icon {
  cursor: pointer;
  margin-top: 5px;
  font-size: 24px;
}

.note__info--header {
  font-size: 16px;
  font-weight: 600;
}

.note__info--body {
  margin-bottom: 15px;
}

.notification-bar {
  width: 100%;
  height: 40px;
  z-index: 1000;
  opacity: 0;
  -o-transition: opacity .4s ease;
  background-color: #000;
  transition: opacity .4s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.notification-bar__body {
  width: calc(100% - 40px);
  height: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

.notification-bar__close {
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #ddd;
  font-size: 24px;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

.notification-bar__success {
  background-color: #a9ea9f;
}

.notification-bar__alert {
  background-color: #ffed00;
}

.notification-bar__error {
  background-color: #f56b6d;
}

.notification-screen {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 4px #00000080;
}

.notification-screen__body {
  width: 480px;
  height: 280px;
}

.notification-screen__content {
  width: 90%;
  text-align: center;
  margin: 40px auto 0;
}

#notification {
  z-index: 1000;
}

.notification {
  width: 440px;
  height: auto;
  z-index: 23;
  position: fixed;
  top: 0;
  right: 0;
}

.page {
  max-width: 100%;
  -o-transition: opacity .2s ease-in-out;
  margin: 0 auto;
  padding: 30px 30px 1px;
  transition: opacity .2s ease-in-out;
  overflow-x: visible;
}

.page__header {
  color: #000;
  margin: 0 0 10px;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}

.page__header span {
  color: #000000b3;
  font-size: 16px;
  font-weight: 400;
}

.page__header--title {
  display: inline-block;
}

.page__header--back {
  cursor: pointer;
  margin-right: 10px;
  font-size: 30px;
  display: inline-block;
}

.content-popup {
  width: 400px;
  height: 400px;
  max-width: 100%;
  max-height: 90%;
  opacity: 0;
  -o-transition: opacity .1s ease-in-out;
  background-color: #f5f5f5;
  margin: 0 auto;
  padding: 30px 30px 1px;
  transition: opacity .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.details-popup {
  min-width: 320px;
  color: #606060;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 50px;
  transition: opacity .2s ease-in-out;
  position: relative;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 14px #00000080;
}

.details-popup__center {
  top: 50%;
  transform: translate(-50%, -50%);
}

.details-popup__header {
  color: #606060;
  min-height: 32px;
  padding: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  position: relative;
}

.details-popup__header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.details-popup__actions {
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}

.details-popup__actions i {
  width: 32px;
  height: 32px;
  color: #999;
  cursor: pointer;
  text-align: center;
  -o-transition: color .1s ease-in-out;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  transition: color .1s ease-in-out;
}

.details-popup__actions i:hover {
  color: #6d6d6d;
}

.details-popup__block {
  width: calc(100% - 20px);
  height: calc(100% - 32px);
  margin-left: 10px;
  padding-bottom: 17px;
  position: relative;
}

.details-popup__disabled {
  width: 100%;
  height: calc(100% - 40px);
  z-index: 10;
  background-color: #fffffff2;
  position: absolute;
  top: 32px;
  left: 0;
}

.details-popup__disabled span {
  width: 80%;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.details-popup__block::-webkit-scrollbar {
  width: 10px;
  background-color: #0000;
}

.details-popup__block::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
}

.details-popup2 {
  max-height: calc(100vh - 100px);
  max-width: 90%;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  flex-direction: column;
  flex-grow: 1;
  margin: 50px 0;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
  box-shadow: 0 0 14px #00000080;
}

.details-popup2__center {
  top: 50%;
  transform: translate(-50%, -50%);
}

.details-popup2__header {
  color: #606060;
  min-height: 32px;
  padding: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  position: relative;
}

.details-popup2__header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.details-popup2__actions {
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}

.details-popup2__actions i {
  width: 32px;
  height: 32px;
  color: #999;
  cursor: pointer;
  text-align: center;
  -o-transition: color .1s ease-in-out;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  transition: color .1s ease-in-out;
}

.details-popup2__actions i:hover {
  color: #6d6d6d;
}

.details-popup2__content {
  height: calc(100% - 32px);
  position: relative;
  overflow-y: scroll;
}

.details-popup2__block {
  width: calc(100% - 20px);
  height: calc(100% - 32px);
  margin-left: 10px;
  padding-bottom: 17px;
  position: relative;
}

.details-popup2__disabled {
  width: 100%;
  height: calc(100% - 40px);
  z-index: 10;
  background-color: #fffffff2;
  position: absolute;
  top: 32px;
  left: 0;
}

.details-popup2__disabled span {
  width: 80%;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.details-popup__block::-webkit-scrollbar {
  width: 10px;
  background-color: #0000;
}

.details-popup__block::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
}

.popup-header {
  color: #606060;
  min-height: 32px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  position: relative;
}

.popup-header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.popup-header__padding {
  padding-bottom: 70px;
}

.popup-header__filter {
  width: 200px;
  position: relative;
}

.popup-header__filter span {
  width: 32px;
  height: 30px;
  color: gray;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px !important;
}

.popup-header__filter input {
  width: 144px;
  height: 30px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: absolute;
  top: 0;
  left: 32px;
  box-shadow: inset 0 1px 1px #00000013;
}

.popup-header__actions {
  padding-right: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.popup-header__actions i {
  height: 32px;
  color: #999;
  cursor: pointer;
  -o-transition: color .1s ease-in-out;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  transition: color .1s ease-in-out;
}

.popup-header__actions i:hover {
  color: #6d6d6d;
}

.popup {
  min-height: 120px;
  min-width: 320px;
  max-height: calc(100vh - 100px);
  max-width: 90%;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  flex-grow: 1;
  margin: 50px 0;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 14px #00000080;
}

.popup__header {
  width: 100%;
  height: 32px;
  min-width: 320px;
  background-color: #f5f5f5;
  flex-shrink: 0;
  display: flex;
  position: relative;
}

.popup__content {
  width: 100%;
  height: calc(100% - 32px);
  display: block;
  position: relative;
  overflow-y: auto;
}

.popup__content--padding {
  width: calc(100% - 20px);
  margin-left: 10px;
}

.popup__children {
  margin-left: 20px;
}

.table-popup {
  min-height: 120px;
  min-width: 320px;
  max-height: calc(100vh - 100px);
  max-width: 90%;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  flex-grow: 1;
  margin: 50px 0;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
  box-shadow: 0 0 14px #00000080;
}

.table-popup__header {
  width: 100%;
  height: 32px;
  min-width: 320px;
  background-color: #f5f5f5;
  flex-shrink: 0;
  display: flex;
  position: relative;
}

.table-popup__content {
  width: 100%;
  height: calc(100% - 32px);
  display: block;
  position: relative;
  overflow-y: auto;
}

.table-popup__children {
  margin-left: 20px;
}

.widget-popup {
  max-width: 96%;
  max-height: 80%;
  color: #606060;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  padding: 12px 17px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 14px #00000080;
}

.widget-popup__header {
  color: #606060;
  min-height: 30px;
  max-width: 96%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
}

.widget-popup__header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.widget-popup__header--close {
  width: 32px;
  height: 32px;
  color: #606060;
  text-align: center;
  cursor: pointer;
  -o-transition: all .2s ease-in-out;
  line-height: 32px;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
}

.widget-popup__header--close:hover {
  color: #000;
}

.widget-popup__header--add {
  width: 32px;
  height: 32px;
  color: #606060;
  text-align: center;
  cursor: pointer;
  -o-transition: all .2s ease-in-out;
  line-height: 32px;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
  right: 32px;
}

.widget-popup__header--add:hover {
  color: #000;
}

.widget-popup__block {
  height: calc(100% - 30px);
  margin: 0 -12px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.widget-popup__content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  position: relative;
}

.widget-popup__content:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

.widget-popup__content--alert {
  padding: 20px;
  font-size: 32px;
}

.widget-popup__disabled {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffffbf;
  position: relative;
  top: 0;
  left: 0;
}

.cash-out__label {
  text-align: right;
  line-height: 37px;
}

.cash-out__form-group {
  margin-bottom: 5px;
}

.daily-activities {
  max-width: 420px;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.daily-activities__summary-table {
  padding-bottom: 7px;
}

.daily-activities__table {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  font-size: 14px;
}

.daily-activities__table td {
  border-top: 1px solid #f0f0f0;
  padding: 6px 4px;
}

.daily-activities__table td:first-child {
  padding-left: 16px;
}

.daily-activities__table td:nth-child(n+1) {
  text-align: right;
  padding-right: 16px;
}

.daily-activities__table tr:hover {
  background-color: #d0d0e140;
}

.daily-activities__activities-table tr {
  cursor: pointer;
}

.daily-activities__activities-table tr:hover {
  background-color: #d0d0e140;
}

.daily-activities__details {
  width: 350px;
  height: 300px;
  position: sticky;
  top: 0;
}

.daily-activities__submit {
  padding: .36rem 1rem !important;
}

.discounts {
  width: 100%;
}

.discounts tr:nth-child(2n+1) {
  background-color: #ddd;
}

.discounts tr:nth-child(2n) {
  background-color: #f5f5f5;
}

.discounts tr td:first-child {
  width: 120px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.discounts tr td:nth-child(2), .discounts tr td:nth-child(3) {
  width: 100px;
}

.discounts tr td:nth-child(4) {
  width: 120px;
  padding: 0 5px;
}

.discounts tr td:nth-child(5) {
  width: 120px;
  padding: 5px;
}

.pos-layout__guest-list {
  width: 100%;
  min-height: 60px;
  cursor: pointer;
  background-color: #f2f2f2;
  border: 1px solid #dedede59;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.pos-layout__guest-list--title {
  color: #000;
  min-height: 30px;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: 700;
}

.register-transactions__row {
  margin-bottom: 0 !important;
}

.register-transactions__block {
  -o-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
  overflow: hidden;
}

.register-transactions__price {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  font-size: 24px;
}

.bulk-register__title {
  padding: 0 1.5rem;
  font-size: 30px;
  font-weight: 500;
}

.bulk-register__block {
  color: #777;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 2rem;
  font-size: 17px;
}

.bulk-register__cash-input {
  max-width: 90% !important;
}

.bulk-register__summary {
  padding: 1rem;
  font-weight: bold;
}

.bulk-register__table-header {
  max-width: 96%;
  border-bottom: 2px solid #a9a9a9;
  padding-left: 1rem;
  font-size: 18px;
  font-weight: bold;
}

.bulk-register__registers {
  padding: 1rem;
}

.bulk-register__register {
  padding: .5rem;
  margin-bottom: 0 !important;
}

.bulk-register__price {
  padding-left: 0 !important;
}

.bulk-register__invalid {
  border: 2px solid red;
  border-radius: 3px;
}

.bulk-register-transactions-header {
  width: 100%;
  left: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bulk-register-transactions-header ul {
  height: 40px;
  z-index: 1;
  top: 30px;
}

.bulk-register-transactions-header ul li {
  height: 40px;
  color: #fff;
  cursor: pointer;
  -o-transition: background-color .2s ease-in-out;
  background-color: #a9a9a9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: 6px;
  padding: 0 20px;
  font-size: 20px;
  line-height: 18px;
  transition: background-color .2s ease-in-out;
}

.bulk-register-transactions-header__active {
  color: #000 !important;
  background-color: #fff !important;
}

.daily-snapshot {
  float: left;
  width: calc(16% - 30px);
  padding: 0 15px;
}

@media only screen and (max-width: 1366px) {
  .daily-snapshot {
    width: calc(41% - 30px);
  }
}

.daily-snapshot__title {
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 1366px) {
  .daily-snapshot__title {
    font-size: 14px;
  }
}

.daily-snapshot__calendar {
  height: 20px;
  color: #606060cc;
  cursor: pointer;
  font-weight: 400;
  line-height: 25px;
}

.daily-snapshot__calendar i {
  padding-right: 5px;
  font-size: 16px;
}

.daily-snapshot__total {
  color: #5c90d2;
  font-size: 36px;
  font-weight: 400;
}

.test {
  max-height: calc(100vh - 100px);
  max-width: 90%;
  background-color: #fff;
  flex-direction: column;
  flex-grow: 1;
  margin: 50px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.test__header {
  width: 100%;
  height: 32px;
  min-width: 320px;
  background-color: #f5f5f5;
  flex-shrink: 0;
  display: flex;
  position: relative;
}

.test__content {
  width: 100%;
  height: calc(100% - 32px);
  display: block;
  position: relative;
  overflow-y: auto;
}

.test-smokescreen {
  width: 100%;
  height: 100%;
  z-index: 23;
  -o-transition: opacity .1s ease-in-out;
  background-color: #000c;
  transition: opacity .1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.test-smokescreen__white {
  background-color: #00000040;
}

.test-smokescreen__transparent {
  background-color: #0000;
}

.step {
  float: left;
  color: #fff;
  background: #bbb;
  border-radius: .25rem;
  margin: 3px;
  padding: 5px;
}

.step__active {
  background: #0275d8;
  font-weight: bold;
}

.detail-edit {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.export-table__block {
  height: calc(100% - 30px);
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.export-table__left {
  width: calc(10% - 10px);
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.export-table__right {
  width: 80%;
  margin-bottom: 10px;
  display: inline-block;
}

.export-table__right input {
  width: 100%;
}

.group-select {
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: crosshair;
  position: fixed;
  top: 0;
  left: 0;
}

.group-select__box {
  width: 0;
  height: 0;
  background-color: #00b62633;
  border: 1px solid #00b626;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.group-select__count {
  width: 300px;
  height: 120px;
  text-align: center;
  background-color: #fff;
  border-top-left-radius: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 20px #000;
}

.group-select__count--title {
  width: 100%;
  margin-top: 15px;
  font-size: 24px;
}

.group-select__count--count {
  font-size: 42px;
  font-weight: 600;
}

.print-table__header {
  margin-bottom: 20px;
  font-size: 24px;
}

.print-table__check {
  width: 40px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.print-table__title {
  width: 310px;
  margin-bottom: 10px;
  display: inline-block;
}

.print-table__title input {
  width: 100%;
}

.print-table__align {
  width: 110px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.print-table__align input {
  width: 100%;
}

.print-table__table {
  width: auto;
  height: auto;
  background-color: #fff;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

.shift-select__cog {
  text-align: center;
  font-size: 16px;
}

.shift-select i {
  cursor: pointer;
}

.shift-select__block {
  width: 200px;
  z-index: 9999;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 14px #00000080;
}

.shift-select__title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.shift-select__action {
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;
}

.sticky-header {
  max-width: 100%;
  opacity: 0;
  position: absolute;
  top: 40px;
}

.table-2 {
  width: 100%;
}

.table-2__overflow {
  position: relative;
  overflow-x: auto;
}

.table-2__stretch {
  margin: 0 -17px -27px;
}

.table-2__actions {
  width: 1%;
  white-space: nowrap;
}

.table-2__actions i {
  cursor: pointer;
}

.table-2__actions div {
  width: 20px;
  height: 100%;
  text-align: center;
  display: inline-block;
}

.table-2__selected {
  color: #fff !important;
  background-color: #80a8b3 !important;
}

.table-2__hover {
  cursor: pointer;
}

.table-2__hover--dark {
  background-color: #80a8b3cc !important;
}

.table-2__hover--light {
  background-color: #d0d0e140 !important;
}

.table-2__filter {
  position: relative;
}

.table-2__filter--search {
  width: 200px;
  height: 40px;
  position: relative;
}

.table-2__filter--search span {
  width: 32px;
  height: 30px;
  color: gray;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px !important;
}

.table-2__filter--search input {
  width: 144px;
  height: 30px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: absolute;
  top: 0;
  left: 32px;
  box-shadow: inset 0 1px 1px #00000013;
}

.table-2__hover {
  cursor: pointer;
}

.table-2 table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table-2 table th {
  color: #606060;
  text-transform: uppercase;
  white-space: nowrap;
  border-bottom-width: 1px;
  padding: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
}

.table-2 table th img {
  width: 10px;
  position: absolute;
  top: 14px;
  left: -3px;
}

.table-2 table th:first-child, .table-2 table td:first-child {
  padding-left: 16px;
}

.table-2 table th:last-child, .table-2 table td:last-child {
  padding-right: 16px;
}

.table-2 table tr:nth-child(2n+1) {
  background-color: #f9f9f9;
}

.table-2 table .table-row-action i {
  margin-right: 3px;
}

.table-2 table tr td {
  vertical-align: top;
  white-space: nowrap;
  border-top: 1px solid #ddd;
  padding: 10px;
  line-height: 1.42857;
}

.table-2 table tr td input[type="checkbox"] {
  cursor: pointer;
}

.table-2 table__search {
  text-align: right;
}

.table-2 table__search label {
  font-size: 13px;
  font-weight: 400;
}

.table-2 table__search input {
  margin-left: 10px;
}

.table-2__pages {
  position: relative;
}

.table-2__pages--title {
  cursor: pointer;
  font-size: 12px;
}

.table-2__pages--overflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.table-2__pages--dropdown {
  width: 100px;
  height: 200px;
  z-index: 2;
  -o-transition: opacity .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #0003;
  border-radius: 6px;
  padding: 6px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 18px;
  left: -2px;
  overflow: hidden;
  box-shadow: 0 6px 12px #0000002d;
}

.table-2__pages--dropdown div {
  cursor: pointer;
}

.table-2__page-bar {
  height: 20px;
  padding-top: 10px;
}

.table-2__page-bar--btn {
  width: 23px;
  color: #606060;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
  font-size: 12px;
  line-height: 1.42857;
  display: inline-block;
}

.table-2__page-bar--btn:hover, .table-2__page-bar--active {
  background-color: #eee;
}

.table-2 .col-10 {
  width: 10%;
}

.table-2 .col-20 {
  width: 20%;
}

.table-2 .col-30 {
  width: 30%;
}

.table-2 .col-40 {
  width: 40%;
}

.table-2 .col-50 {
  width: 50%;
}

.table-2 .col-60 {
  width: 60%;
}

.table-2 .col-70 {
  width: 70%;
}

.table-2 .col-80 {
  width: 80%;
}

.table-2 .col-90 {
  width: 90%;
}

.table-2__no-select {
  -webkit-user-select: none;
  user-select: none;
}

.table-2__sort-icon {
  margin-left: 10px;
  line-height: 18px;
}

.table-pagination2 {
  height: 20px;
  padding-top: 10px;
}

.table-pagination2__btn {
  width: 23px;
  color: #606060;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
  font-size: 12px;
  line-height: 1.42857;
  display: inline-block;
}

.table-pagination2__btn:hover, .table-pagination2__active {
  background-color: #eee;
}

.table-pagination {
  width: 305px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.table-pagination div {
  float: left;
  height: 30px;
  text-align: center;
  color: #383838;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
}

.table-pagination div span {
  font-size: 14px;
}

.table-pagination div i {
  font-size: 24px;
  line-height: 30px;
}

.table-pagination__first {
  width: 40px;
  border-right: 1px solid #ddd;
}

.table-pagination__last {
  width: 40px;
  border-left: 1px solid #ddd;
}

.table-pagination__label {
  width: 40px;
}

.table-pagination__total {
  width: 140px;
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-size: 14px !important;
}

.table-settings__row {
  height: 24px;
  -o-transition: all .1s ease-in-out;
  padding-left: 20px;
  transition: all .1s ease-in-out;
  position: relative;
}

.table-settings__row:hover {
  background-color: #f5f5f5;
}

.table-settings__row:hover > .table-settings__row--order {
  display: inline-block;
}

.table-settings__row--order {
  width: auto;
  height: 24px;
  font-size: 32px;
  line-height: 24px;
  display: none;
  position: absolute;
  top: 0;
  right: 20px;
}

.table-settings__row--order i {
  cursor: pointer;
  margin-left: 10px;
}

.table {
  width: calc(100% - 20px) !important;
}

.table__overflow {
  position: relative;
  overflow-x: auto;
}

.table__actions {
  width: 1%;
  white-space: nowrap;
}

.table__actions i {
  cursor: pointer;
}

.table__actions div {
  width: 20px;
  height: 100%;
  text-align: center;
  display: inline-block;
}

.table table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table table th {
  text-transform: uppercase;
  color: #646464;
  white-space: nowrap;
  padding: 8px 10px;
  font-size: 15px;
  font-weight: 600;
  position: relative;
}

.table table th img {
  width: 10px;
  position: absolute;
  top: 14px;
  left: -3px;
}

.table table td:first-child {
  border-left: 0 !important;
}

.table table .table-row-action i {
  margin-right: 3px;
}

.table table td {
  white-space: nowrap;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
}

.table table td input[type="checkbox"] {
  cursor: pointer;
}

.table table__search {
  text-align: right;
}

.table table__search label {
  font-size: 13px;
  font-weight: 400;
}

.table table__search input {
  margin-left: 10px;
}

.table__pages--title {
  font-size: 12px;
}

.table__pages--page {
  cursor: pointer;
  color: #ddd;
  margin: 0 3px;
}

.table__pages--page:hover, .table__pages--active {
  color: #000;
}

.table .col-10 {
  width: 10%;
}

.table .col-20 {
  width: 20%;
}

.table .col-30 {
  width: 30%;
}

.table .col-40 {
  width: 40%;
}

.table .col-50 {
  width: 50%;
}

.table .col-60 {
  width: 60%;
}

.table .col-70 {
  width: 70%;
}

.table .col-80 {
  width: 80%;
}

.table .col-90 {
  width: 90%;
}

.table__no-select {
  -webkit-user-select: none;
  user-select: none;
}

.tableRow__disable {
  opacity: .5;
  background-color: "red";
}

.tableRow__disable :hover {
  cursor: not-allowed;
}

.widget-controls {
  position: absolute;
  top: 0;
  right: 0;
}

.widget-controls i {
  color: #999;
  cursor: pointer;
  -o-transition: color .1s ease-in-out;
  margin-left: 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  transition: color .1s ease-in-out;
}

.widget-controls i:hover {
  color: #6d6d6d;
}

.widget-header {
  color: #606060;
  min-height: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
}

.widget-header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.widget-header__filter {
  width: 200px;
  margin-right: 20px;
  position: relative;
}

.widget-header__filter span {
  width: 31px;
  height: 38px;
  color: gray;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px !important;
}

.widget-header__filter input {
  width: 144px;
  height: 38px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: absolute;
  top: 0;
  left: 32px;
  box-shadow: inset 0 1px 1px #00000013;
}

.debounce-widget-header {
  color: #606060;
  min-height: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  position: relative;
}

.debounce-widget-header span {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.debounce-widget-header .debounce-search-icon {
  width: 31px;
  height: 39.5px;
  color: gray;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 19px;
  left: 0;
  line-height: 30px !important;
}

.debounce-widget-header .debounce-input {
  left: 47px;
}

.debounce-widget-header .debounce-input fieldset {
  border-radius: 0 6px 6px 0;
}

.widget {
  box-sizing: content-box;
  color: #606060;
  -o-transition: opacity .2s ease-in-out;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 30px;
  padding: 12px 17px;
  transition: opacity .2s ease-in-out;
  position: relative;
}

.widget__block {
  width: calc(100% + 34px);
  height: calc(100% - 45px);
  margin: 0 -17px;
  overflow-y: auto;
}

.widget__overflow {
  margin: 10px -17px 0;
}

.widget__float-input {
  margin: 0 20px 10px 0;
  display: inline-block;
}

.widget__float-input--label {
  font-color: #646464;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.6px;
}

.widget__buttons {
  width: 100%;
  margin-top: 20px;
}

.widget__buttons:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

.widget__buttons--row {
  margin-bottom: 7px;
}

.widget__buttons--row:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

.widget__buttons--right {
  float: right;
  min-width: 80px;
}

.widget__buttons--left {
  float: left;
  min-width: 80px;
}

.widget__buttons--std {
  min-width: 80px;
}

.widget__buttons--left-danger {
  float: left;
  min-width: 80px;
}

.widget__buttons--right-danger {
  float: right;
  min-width: 80px;
}

.widget__buttons--left-warning {
  float: left;
  min-width: 80px;
}

.widget__buttons--right-warning {
  float: right;
  min-width: 80px;
}

.widget__title {
  color: #606060;
  min-height: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.widget .upload-btn {
  margin-top: 20px;
  margin-right: 20px;
}

.widget .increase-btn {
  width: 30px;
  height: 35px;
  color: #000;
  margin-left: 10px;
}

.widget .increase-btn i {
  margin-top: 5px;
}

.zone-details-input-container {
  display: flex;
}

.zone-details-input-stretch {
  flex: 1;
}

.smokescreen {
  width: 100%;
  height: 100%;
  z-index: 23;
  -o-transition: opacity .1s ease-in-out;
  background-color: #0b203380;
  transition: opacity .1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 4px #00000080;
}

.smokescreen__white, .smokescreen__transparent {
  background-color: #0b203380;
}

body {
  overflow: hidden;
}

.clear:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

.upload-button label > * {
  pointer-events: none;
}

.upload-button .upload-button-file {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -100000000;
  position: absolute;
  overflow: hidden;
}

.label-designer canvas {
  height: 4in;
  width: 10in;
  border-radius: 6px;
  background: #fff !important;
  border: 1px solid #d3d3d3 !important;
}

.content {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px 30px 1px;
  overflow-x: visible;
}

.main-container {
  min-height: 100%;
}

@media only screen and (min-width: 1600px) {
  .max-1600 {
    max-width: 950px;
  }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  font-family: sans;
  line-height: 1.15;
}

body {
  height: 100%;
  margin: 0;
}

#template {
  height: 100%;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:active, a:hover {
  outline-width: 0;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button {
  cursor: pointer;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button {
  border: 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

ul, p {
  margin: 0;
  padding: 0;
}

body {
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.42857;
}

* {
  -webkit-overflow-scrolling: touch;
}

ol {
  padding-left: 30px;
}

.documentation {
  margin: 10px;
  padding: 10px;
}

.indent {
  padding: 2px;
}

.metrc {
  background: #a7ffa7;
}

.biot {
  background: #a7e7ff;
}

a:focus, a:hover {
  color: inherit;
}

.tmpt:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

.background__yellow {
  background-color: #2b6e80 20%;
  background-image: radial-gradient(farthest-side at 10% 0, #2b6e80 20%, #0c3252);
  background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
  background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
}

.txt-right {
  text-align: right;
}

.flatpickr-calendar.open {
  z-index: 100;
}

/*# sourceMappingURL=index.11821bd0.css.map */
