.customer-journal {
  &__item {
    min-height: 30px;
    line-height: 30px;
    border-bottom: 1px solid $grey-1;
    cursor: pointer;

    @include transition(all, 0.1s);

    &:hover {
      background-color: $grey-1;
    }
  }

  &__subject {
    margin-bottom: 10px;
  }

  &__body {
    margin-bottom: 10px;
    height: 120px;
  }

  &__filter {
    width: 144px;
    height: 30px;
    font-size: 13px;
  }
}
