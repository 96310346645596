.daily-snapshot {
  float: left;
  width: calc(16% - 30px);
  padding: 0 15px;

  @include MQBelow('L') {
    width: calc(41% - 30px);
  }

  &__title {
    font-size: 18px;
    font-weight: 700;

    @include MQBelow('L') {
      font-size: 14px;
    }
  }

  &__calendar {
    height: 20px;
    line-height: 25px;
    font-weight: 400;
    color: rgba($grey-3, 0.8);
    cursor: pointer;

    i {
      font-size: 16px;
      padding-right: 5px;
    }
  }

  &__total {
    font-size: 36px;
    font-weight: 400;
    color: #5c90d2;
  }
}
