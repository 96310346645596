@import 'users/base';
@import 'customers/base';

@import 'timeclock';

.description-table {
  width: 450px;
}

.token-field {
  width: 150px;
}

.label-container {
  div::-webkit-scrollbar-track {
    background-color: #f3f5f6;
  }

  div::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #34d2af;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 1.5px;
    background-color: #34d2af;
  }
}
