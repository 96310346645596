.graph-by-month {
  position: relative;

  &__canvas {
    width: 100%;
    // height: 400px;
  }

  &__controls {
    position: relative;
    width: 100%;
    // height: 50px;
    padding: 10px 0;
    margin: 20px 0;
    border-top: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;

    &--change {
      display: inline-block;
      margin-left: 30px;
      cursor: pointer;

      i {
        padding: 0 10px;
      }
    }

    &--divider {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background-color: $grey-4;
      display: inline-block;
    }
  }

  &__graph-type {
    width: 300px;

    @extend .input-text;
  }
}
