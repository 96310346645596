.page {
  margin: 0 auto;
  padding: 30px 30px 1px;
  max-width: 100%;
  overflow-x: visible;

  @include transition(opacity, 0.2s);

  &__header {
    // color: $white;
    color: $black;
    font-size: 30px;
    line-height: 30px;
    font-weight: 300;
    margin: 0 0 10px 0;

    span {
      font-size: 16px;
      font-weight: 400;
      // color: rgba($white, .7);
      color: rgba($black, 0.7);
    }

    &--title {
      display: inline-block;
    }

    &--back {
      font-size: 30px;
      margin-right: 10px;
      cursor: pointer;
      // float: right;
      display: inline-block;
    }

    div {
      // display: inline-block;
    }
  }
}
