//
// Spinner
// ------------------------------
@import 'mixins';

@mixin Select-spinner($size, $orbit, $satellite) {
  @include animation(Select-animation-spin 400ms infinite linear);
  @include square($size);
  box-sizing: border-box;
  border-radius: 50%;
  border: 3.75px solid $orbit;
  border-right-color: $satellite;
  display: inline-block;
  position: relative;
}
