.multi-dimension-table-report {
  overflow-x: auto;
  white-space: nowrap;
}
.multi-dimension-table-report table {
  thead {
    border-top-width: 5px;
    border-right-width: 5px;
    border-left-width: 5px;
    border-bottom-width: 0px;
    border-style: solid;
    border-color: $grey-1;
  }

  tbody {
    border-top-width: 0px;
    border-right-width: 5px;
    border-left-width: 5px;
    border-bottom-width: 5px;
    border-style: solid;
    border-color: $grey-1;
  }
}

.y-dimension-container {
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}

.multi-dimension-table-report td,
.multi-dimension-table-report th {
  padding: 1px;
  border-style: solid;
  border-width: 2px;
  border-color: $grey-1;
}
.y-header,
.x-header {
  text-align: center;
}
