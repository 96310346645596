.tableRow {
  &__disable {
    opacity: 0.5;
    background-color: 'red';

    :hover {
      cursor: not-allowed;
    }
  }
}
