.step {
  float: left;
  padding: 5px;
  margin: 3px;
  background: #bbb;
  color: #fff;
  border-radius: 0.25rem;

  &__active {
    font-weight: bold;
    background: #0275d8;
  }
}
