$black: #000000;
$white: #ffffff;

$sidebar-color: #2c343f;
// $sidebar        : rgba(#6882aa, .75);
$sidebar-dark: #232a32;

$ll-green: #06b12c;

$blue-dark: #3e4a59;

$font-color: #646464;

$red: #ff0000;
$red-1: #ff4d4d;
$red-2: #ffcccc;
$red-3: #be0000;

$green: #00b626;
$green-txt: #00b626;
$green-2: #a9ea9f;

$yellow: #f7dc40;
$yellow-1: #dbc648;

$blue: #5bc0de;
$blue-2: #5eb6d1;

$input-error: rgba(#ff0000, 0.3);

$notification-success: #a9ea9f;
$notification-alert: #ffed00;
$notification-error: #f56b6d;

// $green          : #00B626;
// $green-txt      : #00B626;
// $red            : #ff0000;

// $grey           : #eaeaea;
// $grey-darker    : #acacac;
// $grey-darkest   : #383838;

// $input          : #dddddd;
// $input-error    : rgba(#ff0000, 0.3);

// $api            : #cc00cc;

$lavender: #e6e6fa;
$grey: #d0d0e1;
$grey-dark: #9494b8;
$orange-light: #f5ad64;
$orange: #f4a142;

$td-row: #ededed;

$grey-1: #f5f5f5;
$grey-2: #dddddd;
$grey-3: #606060;
$grey-4: #383838;

$error: #d26a5c;

$blue: #80a8b3;
// $not-success    : #ffed00;
// $not-error      : #ff001f;
