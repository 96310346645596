.dd-search {
  position: relative;
  z-index: 2;

  &__block {
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: $white;
    z-index: 2;
    background: #fdfdfd;
    border: 1px solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    overflow: hidden;
    display: none;

    @include transition(all, 0.2s);
  }

  &__list {
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;

    &--item {
      width: 100%;
      padding: 4px 10px;
      color: $black;
      cursor: pointer;

      @include transition(all, 0.1s);

      &:hover {
        background-color: #80a8b3;
        color: $white;
      }
    }

    &--selected {
      background-color: #80a8b3;
      color: $white;
    }
  }

  &__refresh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    border-top: 1px solid #80a8b3;
  }
}
