.cash-out {
  &__label {
    text-align: right;
    line-height: 37px;
  }

  &__form-group {
    margin-bottom: 5px;
    // @extend .form-group;
  }
}
