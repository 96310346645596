.popup-header {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: rgb(96, 96, 96);
  min-height: 32px;
  padding-left: 10px;

  span {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    color: rgb(153, 153, 153);
  }

  &__padding {
    padding-bottom: 70px;
  }

  &__filter {
    position: relative;
    width: 200px;
    // float: right;
    // margin-left: 20px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 30px;
      // padding: 4px 10px;
      font-size: 12px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      color: #808080;
      display: inline-block;
      background-color: #eeeeee;
      border: 1px solid #ccc;
      line-height: 30px !important;
      text-align: center;
    }

    input {
      position: absolute;
      top: 0;
      left: 32px;
      width: 144px;
      height: 30px;
      padding: 0 12px;
      font-size: 13px;
      line-height: 1.428571429;
      color: #555;
      background-color: #fff;
      background-image: none;

      border: 1px solid #ccc;
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 5px;

    i {
      // width: 32px;
      height: 32px;
      padding-right: 5px;
      padding-left: 5px;
      font-size: 14px;
      line-height: 32px;
      font-weight: 400;
      color: rgb(153, 153, 153);
      cursor: pointer;
      // text-align: center;

      @include transition(color, 0.1s);

      &:hover {
        color: rgb(109, 109, 109);
      }
    }
  }
}
